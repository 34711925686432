import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    categoryStoreManager,
    fieldStoreManager,
    Group,
    GroupInfo,
    groupStoreManager,
    HierarchicalMetaCategoryId,
    KbActionTypes,
    KbState,
    LoadHierarchicalCategoriesAction,
    LoadHierarchicalCategoriesSuccessAction,
    LoadMainEntitiesAction,
    LoadMainEntitiesSuccessAction,
    MetaCategory,
    MetaField,
    SavableMetaCategoryDto,
    SavableMetaFieldDto,
} from '@synisys/skynet-store-kb-api';
import {
    DynamicEntityActionTypes,
    DynamicEntityStoreCreateAction,
    DynamicEntityStoreDeleteAction,
    DynamicEntityStoreLoadAllAction,
    DynamicEntityStoreLoadOneAction,
    DynamicEntityStoreUpdateAction,
    DynamicEntityStoreUpdateManyAction,
    EntityActionTypes,
    EntityStoreCreateAction,
    EntityStoreDeleteAction,
    EntityStoreErrorAction,
    EntityStoreLoadAllAction,
    EntityStoreLoadOneAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {
    logStoreError,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';

import {List} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError, first, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../kb.utilities';
import {createGroup} from '../serialization/group.serializer';
import {KbService} from '../services/kb.service';
import {extractPrefix} from './kb.reducers';

const logger = createLogger('kb-effects');

@Injectable()
export class KbEffects {
    private static logError(action: EntityStoreErrorAction): void {
        if (get(action.error, 'type') === 'StoreManagerError') {
            logStoreError(<StoreManagerError>action.error, logger);
        } else {
            logger.error('error in kb: error => \n %o', action.error);
        }
    }

    private static clearCorrespondingCache(type: string): void {
        switch (type) {
            case categoryStoreManager().actionType(
                StandardActionTypes.ENTITY_ERROR
            ):
                categoryStoreManager().clearCache();
                return;
            case groupStoreManager().actionType(
                StandardActionTypes.ENTITY_ERROR
            ):
                groupStoreManager().clearCache();
                return;
            case fieldStoreManager().actionType(
                StandardActionTypes.ENTITY_ERROR
            ):
                fieldStoreManager().clearCache();
                return;
            default:
                logger.error('unknown error type received from store %O', type);
        }
    }

    // region categories

    @Effect()
    public loadCategories$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaCategory,
            string,
            SavableMetaCategoryDto
        >
    > = this.actions.pipe(
        ofType(categoryStoreManager().actionType(StandardActionTypes.LOAD_ALL)),
        mergeMap((action: EntityStoreLoadAllAction) =>
            this.kbService.loadCategories(this.toPrefix(action)).pipe(
                map((categories: List<MetaCategory>) =>
                    categoryStoreManager(this.toPrefix(action)).createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        categories
                    )
                ),
                catchError(err => {
                    return of(
                        categoryStoreManager().createAction(
                            StandardActionTypes.ENTITY_ERROR,
                            new StoreManagerError(err, '')
                        )
                    );
                })
            )
        )
    );

    @Effect()
    public loadCategory$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_ONE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaCategory,
            string,
            SavableMetaCategoryDto
        >
    > = this.actions.pipe(
        ofType(categoryStoreManager().actionType(StandardActionTypes.LOAD_ONE)),
        mergeMap((action: EntityStoreLoadOneAction<string>) =>
            this.store
                .select(state => state.kb.categories[this.toPrefix(action)])
                .pipe(
                    first(),
                    mergeMap(categories => {
                        const category = notNil(categories)
                            ? categories.find(
                                  c => c.metaCategoryId.systemName === action.id
                              )
                            : undefined;
                        if (notNil(category)) {
                            return of(
                                categoryStoreManager(
                                    this.toPrefix(action)
                                ).createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    category
                                )
                            );
                        }
                        return this.kbService
                            .loadCategory(action.id, this.toPrefix(action))
                            .pipe(
                                map(c =>
                                    categoryStoreManager(
                                        this.toPrefix(action)
                                    ).createAction(
                                        StandardActionTypes.LOAD_ONE_SUCCESS,
                                        c
                                    )
                                ),

                                catchError(err => {
                                    return of(
                                        categoryStoreManager().createAction(
                                            StandardActionTypes.ENTITY_ERROR,
                                            new StoreManagerError(err, '')
                                        )
                                    );
                                })
                            );
                    })
                )
        )
    );

    @Effect()
    public createCategory$: Observable<
        EntityActionTypes<
            | StandardActionTypes.CREATE_SUCCESS
            | StandardActionTypes.CREATE_FAIL,
            MetaCategory,
            string,
            SavableMetaCategoryDto
        >
    > = this.actions.pipe(
        ofType(categoryStoreManager().actionType(StandardActionTypes.CREATE)),
        mergeMap((action: EntityStoreCreateAction<SavableMetaCategoryDto>) =>
            this.kbService
                .createCategory(action.item, this.toPrefix(action))
                .pipe(
                    map(category =>
                        categoryStoreManager(
                            this.toPrefix(action)
                        ).createAction(
                            StandardActionTypes.CREATE_SUCCESS,
                            category
                        )
                    ),
                    catchError(err => {
                        return of(
                            categoryStoreManager().createAction(
                                StandardActionTypes.CREATE_FAIL,
                                new StoreManagerError(
                                    err,
                                    'could not create category'
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect()
    public updateCategory$: Observable<
        EntityActionTypes<
            | StandardActionTypes.UPDATE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaCategory,
            string,
            SavableMetaCategoryDto
        >
    > = this.actions.pipe(
        ofType(categoryStoreManager().actionType(StandardActionTypes.UPDATE)),
        mergeMap((action: EntityStoreCreateAction<SavableMetaCategoryDto>) =>
            this.kbService
                .updateCategory(action.item, this.toPrefix(action))
                .pipe(
                    map(category =>
                        categoryStoreManager(
                            this.toPrefix(action)
                        ).createAction(
                            StandardActionTypes.UPDATE_SUCCESS,
                            category
                        )
                    ),
                    catchError(err => {
                        return of(
                            categoryStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                new StoreManagerError(
                                    err,
                                    'could not update category'
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect({dispatch: false})
    public categorySuccess$: Observable<void> = this.actions.pipe(
        ofType(
            categoryStoreManager().actionType(
                StandardActionTypes.CREATE_SUCCESS
            ),
            categoryStoreManager().actionType(
                StandardActionTypes.DELETE_SUCCESS
            ),
            categoryStoreManager().actionType(
                StandardActionTypes.UPDATE_SUCCESS
            ),
            categoryStoreManager().actionType(
                StandardActionTypes.UPDATE_MANY_SUCCESS
            )
        ),
        map(action => {
            categoryStoreManager(this.toPrefix(action)).clearCache();
        })
    );

    @Effect()
    public deleteCategory$: Observable<
        EntityActionTypes<
            | StandardActionTypes.DELETE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaCategory,
            string,
            SavableMetaCategoryDto
        >
    > = this.actions.pipe(
        ofType(categoryStoreManager().actionType(StandardActionTypes.DELETE)),
        mergeMap((action: EntityStoreDeleteAction<MetaCategory>) =>
            this.kbService
                .deleteCategory(action.item, this.toPrefix(action))
                .pipe(
                    map(category =>
                        categoryStoreManager(
                            this.toPrefix(action)
                        ).createAction(
                            StandardActionTypes.DELETE_SUCCESS,
                            category
                        )
                    ),
                    catchError(err => {
                        return of(
                            categoryStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                new StoreManagerError(
                                    err,
                                    'could not delete category'
                                )
                            )
                        );
                    })
                )
        )
    );

    // endregion

    // region fields

    @Effect()
    public loadFields$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaField,
            string,
            SavableMetaFieldDto
        >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.LOAD_ALL)),
        mergeMap((action: DynamicEntityStoreLoadAllAction) =>
            this.kbService
                .loadFields(action.subPath, this.toPrefix(action))
                .pipe(
                    map((fields: List<MetaField>) =>
                        fieldStoreManager(this.toPrefix(action)).createAction(
                            StandardActionTypes.LOAD_MANY_SUCCESS,
                            action.subPath,
                            fields
                        )
                    ),
                    catchError(err => {
                        return of(
                            fieldStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                action.subPath,
                                new StoreManagerError(
                                    err,
                                    `could not load fields for ${action.subPath}`
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect()
    public loadMainEntityFields$: Observable<
        | LoadMainEntitiesSuccessAction
        | DynamicEntityActionTypes<
              StandardActionTypes.ENTITY_ERROR,
              MetaField,
              string,
              SavableMetaFieldDto
          >
    > = this.actions.pipe(
        ofType(KbActionTypes.LoadMainEntityFieldNames),
        mergeMap((action: LoadMainEntitiesAction) =>
            this.kbService
                .loadMainEntityFieldNames(
                    action.mainEntityField.metaCategoryId.systemName,
                    action.mainEntityField.systemName,
                    action.prefix
                )
                .pipe(
                    map(
                        fieldNames =>
                            new LoadMainEntitiesSuccessAction(
                                action.mainEntityField,
                                fieldNames,
                                action.prefix
                            )
                    ),
                    catchError(err =>
                        of(
                            fieldStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                '',
                                new StoreManagerError(
                                    err,
                                    `could not load main entity fields for ${action.mainEntityField}`
                                )
                            )
                        )
                    )
                )
        )
    );

    @Effect()
    public loadHierarchicalCategories$: Observable<
        | LoadHierarchicalCategoriesSuccessAction
        | DynamicEntityActionTypes<
              StandardActionTypes.ENTITY_ERROR,
              MetaField,
              string,
              SavableMetaFieldDto
          >
    > = this.actions.pipe(
        ofType(KbActionTypes.LoadHierarchicalCategories),
        mergeMap((action: LoadHierarchicalCategoriesAction) =>
            this.kbService.loadHierarchicalCategories(action.prefix).pipe(
                map(
                    (
                        hierarchicalMetaCategories: List<
                            HierarchicalMetaCategoryId
                        >
                    ) =>
                        new LoadHierarchicalCategoriesSuccessAction(
                            hierarchicalMetaCategories,
                            action.prefix
                        )
                ),
                catchError(err =>
                    of(
                        fieldStoreManager().createAction(
                            StandardActionTypes.ENTITY_ERROR,
                            '',
                            new StoreManagerError(
                                err,
                                `could not load hierarchical Categories`
                            )
                        )
                    )
                )
            )
        )
    );

    @Effect()
    public loadField$: Observable<
        EntityActionTypes<
            StandardActionTypes.LOAD_ALL,
            MetaField,
            string,
            SavableMetaFieldDto
        >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.LOAD_ONE)),
        mergeMap((action: DynamicEntityStoreLoadOneAction<string>) =>
            this.store
                .select(state => state.kb.fields[this.toPrefix(action)])
                .pipe(
                    first(),

                    mergeMap(fields => {
                        const field = notNil(fields)
                            ? fields
                                  .get(action.subPath, List<MetaField>())
                                  .find(
                                      c =>
                                          c.metaFieldId.systemName === action.id
                                  )
                            : undefined;
                        if (notNil(field)) {
                            return of(
                                fieldStoreManager(
                                    this.toPrefix(action)
                                ).createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    action.subPath,
                                    field
                                )
                            );
                        }
                        return this.kbService
                            .loadField(
                                action.subPath,
                                action.id,
                                this.toPrefix(action)
                            )
                            .pipe(
                                map((metaField: MetaField) =>
                                    fieldStoreManager(
                                        this.toPrefix(action)
                                    ).createAction(
                                        StandardActionTypes.LOAD_ONE_SUCCESS,
                                        action.subPath,
                                        metaField
                                    )
                                ),
                                catchError(err => {
                                    return of(
                                        fieldStoreManager().createAction(
                                            StandardActionTypes.ENTITY_ERROR,
                                            action.subPath,
                                            new StoreManagerError(err, '')
                                        )
                                    );
                                })
                            );
                    })
                )
        )
    );

    @Effect()
    public createField$: Observable<
        | DynamicEntityActionTypes<
              | StandardActionTypes.CREATE_SUCCESS
              | StandardActionTypes.CREATE_FAIL,
              MetaField,
              string,
              SavableMetaFieldDto
          >
        | DynamicEntityActionTypes<
              StandardActionTypes.CREATE_SUCCESS,
              Group,
              string
          >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.CREATE)),
        mergeMap(
            (action: DynamicEntityStoreCreateAction<SavableMetaFieldDto>) =>
                this.kbService
                    .createField(
                        action.subPath,
                        action.item,
                        this.toPrefix(action)
                    )
                    .pipe(
                        switchMap(field =>
                            of(
                                ...[
                                    fieldStoreManager(
                                        this.toPrefix(action)
                                    ).createAction(
                                        StandardActionTypes.CREATE_SUCCESS,
                                        action.subPath,
                                        field
                                    ),
                                    action.item.group
                                        ? groupStoreManager(
                                              this.toPrefix(action)
                                          ).createAction(
                                              StandardActionTypes.CREATE_SUCCESS,
                                              action.subPath,
                                              createGroup(action.item.group)
                                          )
                                        : undefined,
                                ].filter(notNil)
                            )
                        ),
                        catchError(err => {
                            return of(
                                fieldStoreManager().createAction(
                                    StandardActionTypes.CREATE_FAIL,
                                    'no sub path',
                                    new StoreManagerError(
                                        err,
                                        'could not create fields'
                                    )
                                )
                            );
                        })
                    )
        )
    );

    @Effect()
    public deleteField$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.DELETE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaField,
            string,
            SavableMetaFieldDto
        >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.DELETE)),
        mergeMap((action: EntityStoreDeleteAction<MetaField>) =>
            this.kbService.deleteField(action.item, this.toPrefix(action)).pipe(
                map(field =>
                    fieldStoreManager(this.toPrefix(action)).createAction(
                        StandardActionTypes.DELETE_SUCCESS,
                        field.metaFieldId.metaCategoryId.systemName,
                        field
                    )
                ),
                catchError(err => {
                    return of(
                        fieldStoreManager().createAction(
                            StandardActionTypes.ENTITY_ERROR,
                            'no sub path',
                            new StoreManagerError(err, 'could not delete field')
                        )
                    );
                })
            )
        )
    );

    @Effect()
    public updateFields$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.UPDATE_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaField,
            string,
            SavableMetaFieldDto
        >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.UPDATE_MANY)),
        mergeMap(
            (action: DynamicEntityStoreUpdateManyAction<SavableMetaFieldDto>) =>
                this.kbService
                    .updateFields(
                        action.subPath,
                        action.items.toList(),
                        this.toPrefix(action)
                    )
                    .pipe(
                        map(updatedFields =>
                            fieldStoreManager(
                                this.toPrefix(action)
                            ).createAction(
                                StandardActionTypes.UPDATE_MANY_SUCCESS,
                                action.subPath,
                                updatedFields
                            )
                        ),
                        catchError(err => {
                            return of(
                                fieldStoreManager().createAction(
                                    StandardActionTypes.ENTITY_ERROR,
                                    'no sub path',
                                    new StoreManagerError(
                                        err,
                                        'could not update fields'
                                    )
                                )
                            );
                        })
                    )
        )
    );

    @Effect()
    public updateField$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.UPDATE
            | StandardActionTypes.UPDATE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            MetaField,
            string,
            SavableMetaFieldDto
        >
    > = this.actions.pipe(
        ofType(fieldStoreManager().actionType(StandardActionTypes.UPDATE)),
        mergeMap(
            (action: DynamicEntityStoreUpdateAction<SavableMetaFieldDto>) =>
                this.kbService
                    .updateField(
                        action.subPath,
                        action.item,
                        this.toPrefix(action)
                    )
                    .pipe(
                        map(field =>
                            fieldStoreManager(
                                this.toPrefix(action)
                            ).createAction(
                                StandardActionTypes.UPDATE_SUCCESS,
                                field.metaFieldId.metaCategoryId.systemName,
                                field
                            )
                        ),
                        catchError(err => {
                            return of(
                                fieldStoreManager().createAction(
                                    StandardActionTypes.ENTITY_ERROR,
                                    'no sub path',
                                    new StoreManagerError(
                                        err,
                                        'could not update field'
                                    )
                                )
                            );
                        })
                    )
        )
    );

    @Effect({dispatch: false})
    public fieldSuccess$: Observable<void> = this.actions.pipe(
        ofType(
            fieldStoreManager().actionType(StandardActionTypes.CREATE_SUCCESS),
            fieldStoreManager().actionType(StandardActionTypes.DELETE_SUCCESS),
            fieldStoreManager().actionType(StandardActionTypes.UPDATE_SUCCESS),
            fieldStoreManager().actionType(
                StandardActionTypes.UPDATE_MANY_SUCCESS
            ),
            categoryStoreManager().actionType(
                StandardActionTypes.UPDATE_MANY_SUCCESS
            )
        ),
        map(action => {
            fieldStoreManager(this.toPrefix(action)).clearCache();
        })
    );

    // endregion

    // region groups

    @Effect()
    public loadGroups$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.ENTITY_ERROR
            | StandardActionTypes.LOAD_MANY_SUCCESS,
            Group,
            string
        >
    > = this.actions.pipe(
        ofType(groupStoreManager().actionType(StandardActionTypes.LOAD_ALL)),
        mergeMap((action: DynamicEntityStoreLoadAllAction) =>
            this.kbService
                .loadGroups(action.subPath, this.toPrefix(action))
                .pipe(
                    map((groups: List<Group>) =>
                        groupStoreManager(this.toPrefix(action)).createAction(
                            StandardActionTypes.LOAD_MANY_SUCCESS,
                            action.subPath,
                            groups
                        )
                    ),
                    catchError(err => {
                        return of(
                            groupStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                action.subPath,
                                new StoreManagerError(
                                    err,
                                    `could not load groups for ${action.subPath}`
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect({dispatch: false})
    public loadGroup$ = this.actions.pipe(
        ofType(groupStoreManager().actionType(StandardActionTypes.LOAD_ONE)),
        mergeMap((action: DynamicEntityStoreLoadOneAction<string>) =>
            // There isn't endpoint for single group, load all
            groupStoreManager(this.toPrefix(action)).loadAll(
                this.store,
                action.subPath
            )
        )
    );

    @Effect()
    public createGroup$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.CREATE_SUCCESS
            | StandardActionTypes.CREATE_FAIL,
            Group,
            string,
            GroupInfo
        >
    > = this.actions.pipe(
        ofType(groupStoreManager().actionType(StandardActionTypes.CREATE)),
        mergeMap((action: DynamicEntityStoreCreateAction<GroupInfo>) =>
            this.kbService
                .createGroup(action.item, action.subPath, this.toPrefix(action))
                .pipe(
                    switchMap(group =>
                        of(
                            groupStoreManager(
                                this.toPrefix(action)
                            ).createAction(
                                StandardActionTypes.CREATE_SUCCESS,
                                action.subPath,
                                group
                            )
                        )
                    ),
                    catchError(err => {
                        return of(
                            groupStoreManager().createAction(
                                StandardActionTypes.CREATE_FAIL,
                                action.subPath,
                                new StoreManagerError(
                                    err,
                                    'could not create group'
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect()
    public deleteGroup$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.DELETE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            Group,
            string,
            GroupInfo
        >
    > = this.actions.pipe(
        ofType(groupStoreManager().actionType(StandardActionTypes.DELETE)),
        mergeMap((action: DynamicEntityStoreDeleteAction<Group>) =>
            this.kbService
                .deleteGroup(
                    action.item.systemName,
                    action.item.metaCategoryId.systemName,
                    this.toPrefix(action)
                )
                .pipe(
                    map(() =>
                        groupStoreManager(this.toPrefix(action)).createAction(
                            StandardActionTypes.DELETE_SUCCESS,
                            action.item.metaCategoryId.systemName,
                            action.item
                        )
                    ),
                    catchError(err => {
                        return of(
                            groupStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                action.subPath,
                                new StoreManagerError(
                                    err,
                                    'could not delete field'
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect()
    public updateGroup$: Observable<
        DynamicEntityActionTypes<
            | StandardActionTypes.UPDATE_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            Group,
            string,
            GroupInfo
        >
    > = this.actions.pipe(
        ofType(groupStoreManager().actionType(StandardActionTypes.UPDATE_MANY)),
        mergeMap((action: DynamicEntityStoreUpdateManyAction<GroupInfo>) =>
            this.kbService
                .updateGroups(
                    action.items.toList(),
                    action.subPath,
                    this.toPrefix(action)
                )
                .pipe(
                    map(updatedGroups =>
                        groupStoreManager(this.toPrefix(action)).createAction(
                            StandardActionTypes.UPDATE_MANY_SUCCESS,
                            action.subPath,
                            updatedGroups
                        )
                    ),
                    catchError(err => {
                        return of(
                            fieldStoreManager().createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                'no sub path',
                                new StoreManagerError(
                                    err,
                                    'could not update fields'
                                )
                            )
                        );
                    })
                )
        )
    );

    @Effect({dispatch: false})
    public groupSuccess$: Observable<void> = this.actions.pipe(
        ofType(
            groupStoreManager().actionType(StandardActionTypes.CREATE_SUCCESS),
            groupStoreManager().actionType(StandardActionTypes.DELETE_SUCCESS),
            groupStoreManager().actionType(StandardActionTypes.UPDATE_SUCCESS),
            categoryStoreManager().actionType(
                StandardActionTypes.UPDATE_MANY_SUCCESS
            )
        ),
        map(action => {
            groupStoreManager(this.toPrefix(action)).clearCache();
        })
    );

    @Effect({dispatch: false})
    public handleError = this.actions.pipe(
        ofType(
            categoryStoreManager().actionType(StandardActionTypes.ENTITY_ERROR),
            groupStoreManager().actionType(StandardActionTypes.ENTITY_ERROR),
            fieldStoreManager().actionType(StandardActionTypes.ENTITY_ERROR)
        ),
        map((action: EntityStoreErrorAction) => {
            KbEffects.clearCorrespondingCache(action.type);
            KbEffects.logError(action);
        }),
        tap(noop, e => logger.error(e))
    );

    // endregion

    constructor(
        private readonly actions: Actions,
        private readonly kbService: KbService,
        private readonly store: Store<KbState>
    ) {}

    private toPrefix(
        action:
            | EntityActionTypes<
                  StandardActionTypes,
                  MetaCategory,
                  string,
                  SavableMetaCategoryDto
              >
            | DynamicEntityActionTypes<
                  StandardActionTypes,
                  MetaField,
                  string,
                  SavableMetaFieldDto
              >
            | DynamicEntityActionTypes<StandardActionTypes, Group, string>
    ): string {
        return extractPrefix(action.additionalData);
    }
}
