import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {localeFeatureKey} from '@synisys/skynet-store-locales-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {LocaleService} from '../services/locale.service';
import {LocaleEffects} from './locale.effects';
import {localesReducer} from './locale.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(localeFeatureKey, localesReducer),
        EffectsModule.forFeature([LocaleEffects]),
    ],
    providers: [LocaleService],
})
export class LocaleStoreModule {}
