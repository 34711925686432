import {Action} from '@ngrx/store';
import {Set} from 'immutable';
import {MetaFieldId} from '../model';
import {KbActionTypes} from './kb.actions.enum';

export class LoadMainEntitiesSuccessAction implements Action {
    public type: string = KbActionTypes.LoadMainEntityFieldNamesSuccess;

    constructor(
        public mainEntityField: MetaFieldId,
        public mainEntityMetaFieldNames: Set<string>,
        public prefix?: string
    ) {}
}
