import {Store} from '@ngrx/store';
import {EntityStoreManager} from '@synisys/skynet-store-manager';
import {notNil} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {Memoize} from 'lodash-decorators';
import {Observable} from 'rxjs/Observable';
import {distinct, filter, publishReplay, refCount} from 'rxjs/operators';
import {
    HierarchicalMetaCategoryId,
    KbState,
    MetaCategory,
    SavableMetaCategoryDto,
} from '..';
import {LoadHierarchicalCategoriesAction} from '../actions/load-hierarchical-categories.action';
import {isNil} from 'lodash';

export class CategoryStoreManager extends EntityStoreManager<
    MetaCategory,
    string,
    SavableMetaCategoryDto
> {
    constructor(
        path: string,
        keyExtractor: (item: MetaCategory) => string,
        prefix: string
    ) {
        super(path, keyExtractor, prefix, isNil);
    }

    @Memoize()
    public loadHierarchicalCategories(
        store: Store<KbState>
    ): Observable<List<HierarchicalMetaCategoryId>> {
        store.dispatch(new LoadHierarchicalCategoriesAction(this.prefix));

        return store
            .select(state => state.kb.hierarchicalCategories[this.prefix!])
            .pipe(
                filter(notNil),
                publishReplay<List<HierarchicalMetaCategoryId>>(1),
                refCount(),
                distinct()
            );
    }

    public clearCache(): void {
        super.clearCache();
        this.loadHierarchicalCategories['cache'].clear();
    }
}
