import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    LanguagesState,
    languageStoreManager,
} from '@synisys/skynet-store-languages-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {List} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError, map, switchMap, tap, throttleTime} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../languages.utilities';
import {LanguageService} from '../services/language.service';
import {
    logStoreError,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';

const logger = createLogger('language-effects');

@Injectable()
export class LanguageEffects {
    private static logError(action: EntityStoreErrorAction): void {
        if (get(action.error, 'type') === 'StoreManagerError') {
            logStoreError(<StoreManagerError>action.error, logger);
        } else {
            logger.error('error while loading: error => \n %o', action.error);
        }
    }
    @Effect()
    public loadLanguages$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            Language,
            number
        >
    > = this.actions$.pipe(
        ofType(languageStoreManager.actionType(StandardActionTypes.LOAD_ALL)),
        tap(() => logger.debug('load all languages action received')),
        throttleTime(1000),
        switchMap(() => this.store.select(state => state.languages.languages)),
        switchMap(loadedLanguages => {
            if (notNil(loadedLanguages) && !loadedLanguages.isEmpty()) {
                return of(
                    languageStoreManager.createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        loadedLanguages
                    )
                );
            }
            logger.debug('requesting language service to load languages');
            return this.languageService.loadLanguages().pipe(
                map(languages =>
                    languageStoreManager.createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        languages
                    )
                ),
                catchError(err => {
                    return of(
                        languageStoreManager.createAction(
                            StandardActionTypes.ENTITY_ERROR,
                            new StoreManagerError(
                                err,
                                'could not load languages'
                            )
                        )
                    );
                })
            );
        })
    );

    @Effect({dispatch: false})
    public printError = this.actions$.pipe(
        ofType(
            languageStoreManager.actionType(StandardActionTypes.ENTITY_ERROR)
        ),
        map((action: EntityStoreErrorAction) => {
            languageStoreManager.clearCache();
            LanguageEffects.logError(action);
        }),
        tap(noop, e => logger.error(e))
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<LanguagesState>,
        private readonly languageService: LanguageService
    ) {}
}
