import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {NullOrUndefined} from '@synisys/skynet-store-utilities';
import {MetaFieldId} from './model/field.models';

export const ENTITY_MULTILINGUAL_NAME_ATTRIBUTE = 'displayNameMultilingual';

export interface EntityWithName {
    /**
     * @deprecated use displayNameMultilingual
     */
    displayName?: string | NullOrUndefined;
    [ENTITY_MULTILINGUAL_NAME_ATTRIBUTE]?: MultilingualString | NullOrUndefined;
}

export type MetaFieldType =
    | 'MONEY'
    | 'CLASSIFIER'
    | 'SUB_ENTITY'
    | 'BOOLEAN'
    | 'MAIN_ENTITY'
    | 'PARENT'
    | 'USER'
    | 'DATE'
    | 'STRING'
    | 'BIG_DECIMAL'
    | 'ACCOUNTING'
    | 'INTEGER_INSTANCE'
    | 'LONG_INSTANCE'
    | 'LOOKUP'
    | 'DOCUMENT'
    | 'DATE_TIME'
    | 'INTEGER_IDENTITY'
    | 'LONG_IDENTITY'
    | 'LOCAL_DATE'
    | 'WORKFLOW_STATE'
    | 'MULTILINGUAL_STRING'
    | 'MULTI_SELECT'
    | 'DOUBLE'
    | 'INTEGER';

export interface SortInfo {
    metaFieldId: MetaFieldId;
    sortingType: 'ASCENDING' | 'DESCENDING';
    sortColumn?: string | null;
}

export interface HierarchicalMetaCategoryId {
    systemName: string;
    isRoot: boolean;
    children: HierarchicalMetaCategoryId[];
}
