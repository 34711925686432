import {ErrorHandler, Injectable} from '@angular/core';
import {EffectSources} from '@ngrx/effects';

let service: SkynetEffectSources | undefined;

@Injectable()
export class SkynetEffectSources extends EffectSources {}

export function skynetEffectSourcesFactory(
    errorHandler: ErrorHandler
): SkynetEffectSources {
    if (!service) {
        service = new SkynetEffectSources(errorHandler);
    }
    return service;
}
