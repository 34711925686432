import {Logger} from './logger';

export class StoreManagerError extends Error {
    public readonly type = 'StoreManagerError';
    constructor(
        public readonly cause: Object,
        message = 'store manager error'
    ) {
        super(message);
    }
}

export function logStoreError(error: StoreManagerError, logger: Logger): void {
    logger.error(
        'store error occurred: cause => %O error => \n %o',
        error.cause,
        error
    );
}
