import {Store} from '@ngrx/store';
import {
    categoryStoreManager,
    defaultKbKey,
    fieldStoreManager,
    groupStoreManager,
    kbFeatureKey,
    KbState,
    MetaCategory,
} from '@synisys/skynet-store-kb-api';
import {StandardActionTypes} from '@synisys/skynet-store-manager';
import {notNil} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {filter, first} from 'rxjs/operators';
import {createLogger} from '../kb.utilities';

export type Operation = 'CREATE' | 'UPDATE' | 'DELETE';

export interface PartialRefreshData {
    categories: string[];
    changes: {[key: string]: Operation};
    prefix: string;
}

export interface MessagingData<T extends object> {
    topicName: string;
    applicationId: string;
    messageDate: number;
    data: T;
}

export interface NotifierService {
    // tslint:disable-next-line:no-any
    subscribe(serviceName: string, topic: string): Observable<any>;
}

const LOGGER = createLogger('kb-reload.service');

export class KbReloadService {
    constructor(
        private store: Store<KbState>,
        private notifier: NotifierService
    ) {}

    public init(): void {
        this.notifier.subscribe('kb-service', 'kb-partial-refresh').subscribe(
            data => {
                const messagingData: MessagingData<PartialRefreshData> = JSON.parse(
                    data
                );

                const prefix = messagingData.data.prefix
                    ? messagingData.data.prefix
                    : defaultKbKey;

                this.reloadByCategory(
                    prefix,
                    messagingData.data.categories.filter(
                        c => messagingData.data.changes[c] === 'UPDATE'
                    )
                );

                this.eraseCategories(
                    prefix,
                    new Set<string>(
                        messagingData.data.categories.filter(
                            c => messagingData.data.changes[c] === 'DELETE'
                        )
                    )
                );
            },
            err => LOGGER.error(err)
        );
    }

    public reloadByCategory(prefix: string, categories: string[]): void {
        this.store
            .select(kbFeatureKey)
            .pipe(
                first(),
                filter(state => notNil(state.categories[prefix]))
            )
            .subscribe(
                state => {
                    categories.forEach(category => {
                        if (
                            get(
                                state.categories,
                                prefix,
                                List<MetaCategory>()
                            ).find(
                                metaCategory =>
                                    metaCategory.metaCategoryId.systemName ===
                                    category
                            )
                        ) {
                            this.store.dispatch(
                                categoryStoreManager(prefix).createAction(
                                    StandardActionTypes.LOAD_ONE,
                                    category
                                )
                            );
                        }
                        if (state.fields[prefix].has(category)) {
                            this.store.dispatch(
                                groupStoreManager(prefix).createAction(
                                    StandardActionTypes.LOAD_ALL,
                                    category
                                )
                            );
                        }
                        if (state.groups[prefix].has(category)) {
                            this.store.dispatch(
                                fieldStoreManager(prefix).createAction(
                                    StandardActionTypes.LOAD_ALL,
                                    category
                                )
                            );
                        }
                    });
                },
                err => LOGGER.error(err)
            );
    }

    public eraseCategories(prefix: string, categories: Set<string>): void {
        this.store
            .select(kbFeatureKey)
            .pipe(
                first(),
                filter(state => notNil(state.categories[prefix]))
            )
            .subscribe(
                state => {
                    get(state.categories, prefix, List<MetaCategory>())
                        .filter(category =>
                            categories.has(category.metaCategoryId.systemName)
                        )
                        .forEach(category => {
                            this.store.dispatch(
                                categoryStoreManager(prefix).createAction(
                                    StandardActionTypes.DELETE_SUCCESS,
                                    category
                                )
                            );
                        });
                },
                err => LOGGER.error(err)
            );
    }
}

let kbReloadService: KbReloadService;

export function kbReloadServiceFactory(
    store: Store<KbState>
): KbReloadService | undefined {
    let notifierEventSourceServiceFactory;
    try {
        notifierEventSourceServiceFactory = require('@synisys/skynet-store-client-notifier')
            .notifierEventSourceServiceFactory;
    } catch (e) {
        LOGGER.warn(
            'cannot import @synisys/idm-client-notifier-js or @synisys/skynet-store-client-notifier, %O',
            e
        );
        return undefined;
    }
    if (!kbReloadService && notifierEventSourceServiceFactory) {
        kbReloadService = new KbReloadService(
            store,
            notifierEventSourceServiceFactory(store)
        );
        kbReloadService.init();
    }
    return kbReloadService;
}
