import {NgZone} from '@angular/core';
import {IScheduler} from 'rxjs/Scheduler';
import {Subscription} from 'rxjs/Subscription';
import {Action} from 'rxjs/scheduler/Action';

export class LeaveZoneScheduler implements IScheduler {
    constructor(private zone: NgZone, private scheduler: IScheduler) {}

    public schedule<T>(
        work: (this: Action<T>, state?: T) => void,
        delay: number = 0,
        state?: T
    ): Subscription {
        return this.zone.runOutsideAngular(() =>
            this.scheduler.schedule(work, delay, state)
        );
    }

    public now(): number {
        return this.scheduler.now();
    }
}

export function leaveZone(zone: NgZone, scheduler: IScheduler): IScheduler {
    return new LeaveZoneScheduler(zone, scheduler);
}
