import {
    Language,
    LanguageDirection,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {StoreLanguage} from '@synisys/skynet-store-languages-api';
import {
    deserializeMultilingualString,
    LanguageDto,
    serializeMultilingualString,
} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';

function deserializeLanguage(dto: LanguageDto): StoreLanguage {
    return new StoreLanguage(
        dto.id,
        deserializeMultilingualString(dto.alias),
        deserializeMultilingualString(dto.acronym),
        dto.isRtl ? LanguageDirection.RTL : LanguageDirection.LTR,
        dto.isDefault,
        dto.charset,
        dto.isInput
    );
}

function serializeLanguage(language: Language): LanguageDto {
    return {
        id: language.getId(),
        acronym: serializeMultilingualString(language.getAcronym()),
        isRtl: language.getDirection() === LanguageDirection.RTL,
        alias: serializeMultilingualString(language.getName()),
        abbreviation: '',
        charset: 'UTF-8',
        isDefault: false,
        isInput: false,
    };
}

export function deserializeLanguages(
    value: LanguageDto[]
): List<StoreLanguage> {
    return List(value.map(deserializeLanguage));
}
