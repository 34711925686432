import {Action} from '@ngrx/store';
import {List} from 'immutable';
import {HierarchicalMetaCategoryId} from '..';
import {KbActionTypes} from './kb.actions.enum';

export class LoadHierarchicalCategoriesSuccessAction implements Action {
    public type: string = KbActionTypes.LoadHierarchicalCategoriesSuccess;

    constructor(
        public hierarchicalMetaCategories: List<HierarchicalMetaCategoryId>,
        public prefix?: string
    ) {}
}
