import {Store} from '@ngrx/store';
import {
    CurrencyModel,
    CurrencyState,
    currencyStoreManager,
} from '@synisys/skynet-store-currencies-api';
import {Logger, notNil, assert} from '@synisys/skynet-store-utilities';
import {Observable} from 'rxjs/Observable';
import {filter, map} from 'rxjs/operators';

/**
 * @deprecated moved to skynet-store-currency-api
 */
export function getDefaultCurrency(
    store: Store<CurrencyState>
): Observable<CurrencyModel> {
    return currencyStoreManager.selectAll(store).pipe(
        filter(value => !value.isEmpty()),
        map(currencies =>
            currencies.valueSeq().find(currency => currency.isDefault)
        ),
        assert(notNil, Error(`Can't find default currency`))
    );
}

export function createLogger(namespace: string): Logger {
    return new Logger('skynet-store-currencies-impl', namespace);
}
