import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {
    Actions,
    EffectSources,
    ɵb as EffectsRootModule,
    ɵd as ROOT_EFFECTS,
    ɵf as EffectsRunner,
} from '@ngrx/effects';
import {ScannedActionsSubject, Store} from '@ngrx/store';
import {skynetActionsFactory} from './effects/skynet-actions.service';
import {skynetEffectSourcesFactory} from './effects/skynet-effect-sources.service';
import {skynetEffectsRunnerFactory} from './effects/skynet-effects-runner.service';

@NgModule({})
export class SkynetEffectsRootModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: EffectsRootModule,
            providers: [
                {
                    provide: EffectsRunner,
                    useFactory: skynetEffectsRunnerFactory,
                    deps: [EffectSources, Store],
                },
                {
                    provide: EffectSources,
                    useFactory: skynetEffectSourcesFactory,
                    deps: [ErrorHandler],
                },
                {
                    provide: Actions,
                    useFactory: skynetActionsFactory,
                    deps: [ScannedActionsSubject],
                },
                {
                    provide: ROOT_EFFECTS,
                    useValue: [],
                },
            ],
        };
    }
}
