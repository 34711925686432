import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {StoreLanguage} from '@synisys/skynet-store-languages-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    LanguageDto,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, switchMap, tap} from 'rxjs/operators';
import {deserializeLanguages} from '../language.serializer';
import {createLogger} from '../languages.utilities';

const logger = createLogger('language-service');

@Injectable()
export class LanguageService {
    private readonly $mlUrl = getProperties(this.store).pipe(
        map(properties => get(properties, 'ml-service-url')),
        assert(
            notNil,
            new StoreManagerError('Url for ml-service-url was not found')
        )
    );

    constructor(
        private readonly store: Store<PropertiesState>,
        private readonly http: HttpClient
    ) {}

    public loadLanguages(): Observable<List<StoreLanguage>> {
        return this.$mlUrl.pipe(
            tap(x => logger.debug('loading languages from url %s', x)),
            switchMap(url =>
                this.http.get(`${url}/languages`).pipe(
                    tap(
                        () =>
                            logger.debug('languages where successfully loaded'),
                        err =>
                            logger.error(
                                'error occurred while loading languages %O',
                                err
                            )
                    ),
                    map(value => deserializeLanguages(<LanguageDto[]>value)),
                    map(languages => List(languages))
                )
            )
        );
    }
}
