import {ModuleWithProviders, NgModule} from '@angular/core';
import {
    _INITIAL_REDUCERS,
    _INITIAL_STATE,
    _REDUCER_FACTORY,
    _STORE_REDUCERS,
    ActionsSubject,
    combineReducers,
    createReducerFactory,
    INITIAL_REDUCERS,
    INITIAL_STATE,
    META_REDUCERS,
    REDUCER_FACTORY,
    ReducerManager,
    ReducerManagerDispatcher,
    ReducerObservable,
    ScannedActionsSubject,
    State,
    StateObservable,
    Store,
    StoreRootModule,
} from '@ngrx/store';
import {skynetActionsSubjectFactory} from './store/skynet-actions-subject.service';
import {skynetReducerManagerFactory} from './store/skynet-reducer-manager.service';
import {skynetScannedActionsSubjectFactory} from './store/skynet-scanned-actions-subject.service';
import {skynetStateFactory} from './store/skynet-state.service';
import {skynetStoreFactory} from './store/skynet-store.service';

@NgModule({})
export class SkynetStoreRootModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: StoreRootModule,
            providers: [
                {provide: _INITIAL_STATE, useValue: undefined},
                {
                    provide: INITIAL_STATE,
                    useExisting: _INITIAL_STATE,
                },
                {provide: _INITIAL_REDUCERS, useValue: {}},
                {
                    provide: _STORE_REDUCERS,
                    useExisting: _INITIAL_REDUCERS,
                },
                {
                    provide: INITIAL_REDUCERS,
                    useExisting: _INITIAL_REDUCERS,
                },
                {
                    provide: META_REDUCERS,
                    useValue: [],
                },
                {
                    provide: _REDUCER_FACTORY,
                    useValue: combineReducers,
                },
                {
                    provide: REDUCER_FACTORY,
                    deps: [_REDUCER_FACTORY, META_REDUCERS],
                    useFactory: createReducerFactory,
                },
                {
                    provide: ActionsSubject,
                    useFactory: skynetActionsSubjectFactory,
                },
                {
                    provide: ReducerManager,
                    useFactory: skynetReducerManagerFactory,
                    deps: [
                        ReducerManagerDispatcher,
                        INITIAL_STATE,
                        INITIAL_REDUCERS,
                        REDUCER_FACTORY,
                    ],
                },
                {
                    provide: Store,
                    useFactory: skynetStoreFactory,
                    deps: [StateObservable, ActionsSubject, ReducerManager],
                },
                {
                    provide: State,
                    useFactory: skynetStateFactory,
                    deps: [
                        ActionsSubject,
                        ReducerObservable,
                        ScannedActionsSubject,
                        INITIAL_STATE,
                    ],
                },
                {
                    provide: ScannedActionsSubject,
                    useFactory: skynetScannedActionsSubjectFactory,
                },
                {provide: ReducerObservable, useExisting: ReducerManager},
                {
                    provide: ReducerManagerDispatcher,
                    useExisting: ActionsSubject,
                },
                {provide: StateObservable, useExisting: State},
            ],
        };
    }
}
