import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';

// tslint:disable-next-line:no-any
let service: SkynetActions<any> | undefined;

@Injectable()
export class SkynetActions<V = Action> extends Actions<V> {}

export function skynetActionsFactory<T>(
    source?: Observable<T>
): SkynetActions<T> {
    if (!service) {
        service = new SkynetActions(source);
    }
    return service;
}
