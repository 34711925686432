import {isNil} from 'lodash';
import {MonoTypeOperatorFunction, OperatorFunction} from 'rxjs/interfaces';
import {map} from 'rxjs/operators';

export function notNil<T>(a: T | null | undefined): a is T {
    return !isNil(a);
}

export function assert<T, K extends T>(
    predicate: (value: T) => value is K,
    error: Error | ((value: T) => Error)
): OperatorFunction<T, K>;
export function assert<T>(
    predicate: (value: T) => boolean,
    error: Error | ((value: T) => Error)
): MonoTypeOperatorFunction<T>;
/**
 * throws 'error' if value is not allowed by the `predicate` function
 * @param  predicate - predicate function
 * @param  error     - error or error supplier
 * @return UnaryFunction
 */
export function assert<T, K extends T>(
    predicate: ((value: T) => value is K) | ((value: T) => boolean),
    error: Error | ((value: T) => Error)
): MonoTypeOperatorFunction<T> {
    return map(value => {
        if (!predicate(value)) {
            if (error instanceof Error) {
                throw error;
            } else {
                throw error(value);
            }
        }
        return value;
    });
}
