export type PropertyMap = {[key: string]: string};

export const propertiesFeatureKey = 'properties';

export interface PropertiesState {
    [propertiesFeatureKey]: {
        map: PropertyMap | undefined;
        error: string | undefined;
    };
}
