import {
    PropertiesActions,
    PropertiesActionTypes,
    propertiesFeatureKey,
    PropertiesState,
    PropertyMap,
} from '@synisys/skynet-store-properties-api';

export function propertiesReducer(
    state: PropertiesState[typeof propertiesFeatureKey] = {
        map: undefined,
        error: undefined,
    },
    action: PropertiesActions
): PropertiesState[typeof propertiesFeatureKey] {
    // tslint:disable-next-line:no-var-keyword
    switch (action.type) {
        case PropertiesActionTypes.GetPropertiesSuccess: {
            return {
                ...state,
                map: action.payload as PropertyMap,
            };
        }
        case PropertiesActionTypes.GetPropertiesError: {
            return {
                ...state,
                error: action.payload as string,
            };
        }
        default:
            return state;
    }
}
