import {Injectable, OnDestroy} from '@angular/core';
import {ActionsSubject} from '@ngrx/store';

let service: SkynetActionsSubject | undefined;

@Injectable()
export class SkynetActionsSubject extends ActionsSubject implements OnDestroy {
    public ngOnDestroy(): void {}
}
export function skynetActionsSubjectFactory(): SkynetActionsSubject {
    if (!service) {
        service = new SkynetActionsSubject();
    }
    return service;
}
