import {
    categoryStoreManager,
    defaultKbKey,
    fieldStoreManager,
    Group,
    groupStoreManager,
    HierarchicalMetaCategoryId,
    KbActionTypes,
    kbFeatureKey,
    KbState,
    LoadHierarchicalCategoriesSuccessAction,
    LoadMainEntitiesSuccessAction,
    MetaCategory,
    MetaField,
    SavableMetaCategoryDto,
    SavableMetaFieldDto,
} from '@synisys/skynet-store-kb-api';
import {
    DynamicEntityActionTypes,
    DynamicEntityStoreCreateSuccessAction,
    DynamicEntityStoreDeleteSuccessAction,
    DynamicEntityStoreLoadAllSuccessAction,
    DynamicEntityStoreLoadOneSuccessAction,
    DynamicEntityStoreUpdateManySuccessAction,
    DynamicEntityStoreUpdateSuccessAction,
    EntityActionTypes,
    EntityStoreCreateAction,
    EntityStoreLoadAllAction,
    EntityStoreLoadAllSuccessAction,
    EntityStoreLoadOneSuccessAction,
    loadingStatusKey,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {notNil} from '@synisys/skynet-store-utilities';
import {List, Map, Set, setIn} from 'immutable';
import {get, isNil} from 'lodash';
import {cloneCategory, cloneEntity} from '../kb.utilities';

export function kbReducer(
    state: KbState[typeof kbFeatureKey] = {
        categories: {
            [defaultKbKey]: undefined,
        },
        fields: {
            [defaultKbKey]: Map(),
        },
        groups: {
            [defaultKbKey]: Map(),
        },
        mainEntityFields: {
            [defaultKbKey]: Map(),
        },
        hierarchicalCategories: {
            [defaultKbKey]: undefined,
        },
        [loadingStatusKey]: {},
    },
    action:
        | EntityActionTypes<
              StandardActionTypes,
              MetaCategory,
              string,
              SavableMetaCategoryDto
          >
        | DynamicEntityActionTypes<
              StandardActionTypes,
              MetaField,
              string,
              SavableMetaFieldDto
          >
        | DynamicEntityActionTypes<StandardActionTypes, Group, string>
): KbState[typeof kbFeatureKey] {
    let prefix: string;
    switch (action.type) {
        // region category

        case categoryStoreManager().actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ): {
            const actualAction = action as EntityStoreLoadAllSuccessAction<
                MetaCategory
            >;
            prefix = extractPrefix(actualAction.additionalData);
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [prefix]: actualAction.items.toList(),
                },
                [loadingStatusKey]: setIn(
                    state[loadingStatusKey],
                    ['categories', prefix],
                    true
                ),
            };
        }
        case categoryStoreManager().actionType(StandardActionTypes.LOAD_ALL): {
            const actualAction = action as EntityStoreLoadAllAction;
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [extractPrefix(actualAction.additionalData)]: List(),
                },
            };
        }
        case categoryStoreManager().actionType(
            StandardActionTypes.LOAD_ONE_SUCCESS
        ): {
            const actualAction = action as EntityStoreLoadOneSuccessAction<
                MetaCategory
            >;
            const category = actualAction.item;
            prefix = extractPrefix(actualAction.additionalData);

            if (isNil(state.categories[prefix])) {
                state.categories[prefix] = List();
            }
            const index = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findIndex(
                value =>
                    value.metaCategoryId.systemName ===
                    category.metaCategoryId.systemName
            );
            const categories =
                index > -1
                    ? get(state.categories, prefix, List<MetaCategory>()).set(
                          index,
                          category
                      )
                    : get(state.categories, prefix, List<MetaCategory>()).push(
                          category
                      );
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [prefix]: categories,
                },
            };
        }
        case categoryStoreManager().actionType(
            StandardActionTypes.CREATE_SUCCESS
        ): {
            const addCategorySuccess = action as EntityStoreCreateAction<
                MetaCategory
            >;
            prefix = extractPrefix(addCategorySuccess.additionalData);
            if (isNil(state.categories[prefix])) {
                state.categories[prefix] = List();
            }
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [prefix]: state.categories[prefix]!.push(
                        addCategorySuccess.item
                    ),
                },
            };
        }
        case categoryStoreManager().actionType(
            StandardActionTypes.UPDATE_SUCCESS
        ): {
            const addCategorySuccess = action as EntityStoreCreateAction<
                MetaCategory
            >;
            prefix = extractPrefix(addCategorySuccess.additionalData);
            const categoryIndex = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findIndex(
                value =>
                    value.metaCategoryId.systemName ===
                    addCategorySuccess.item.metaCategoryId.systemName
            );
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [prefix]: get(
                        state.categories,
                        prefix,
                        List<MetaCategory>()
                    ).set(categoryIndex, addCategorySuccess.item),
                },
            };
        }
        case categoryStoreManager().actionType(
            StandardActionTypes.DELETE_SUCCESS
        ): {
            const deleteCategorySuccess = action as DynamicEntityStoreDeleteSuccessAction<
                MetaCategory
            >;
            prefix = extractPrefix(deleteCategorySuccess.additionalData);

            const deletedCategory = deleteCategorySuccess.item;
            const deleteFields = state.fields[prefix].get(
                deletedCategory.metaCategoryId.systemName
            );
            const removableCategoryNames = [
                deletedCategory.metaCategoryId.systemName,
            ];
            if (deleteFields) {
                removableCategoryNames.push(
                    ...deleteFields
                        .filter(
                            f =>
                                f.metaFieldType === 'SUB_ENTITY' &&
                                f.subEntityMetaCategoryId
                        )
                        .map(f => f.subEntityMetaCategoryId!.systemName)
                        .toArray()
                );
            }

            let fields = state.fields[prefix].removeAll(removableCategoryNames);
            if (
                !deletedCategory.isClassifier &&
                !deletedCategory.isWithVersioning
            ) {
                fields = fields.mapEntries(entry => [
                    entry[0],
                    entry[1].filter(
                        field =>
                            !field.subEntityMetaCategoryId ||
                            field.subEntityMetaCategoryId.systemName !==
                                deletedCategory.metaCategoryId.systemName
                    ),
                ]);
            }

            return {
                ...state,
                groups: {
                    ...state.groups,
                    [prefix]: state.groups[prefix].removeAll(
                        removableCategoryNames
                    ),
                },
                categories: {
                    ...state.categories,
                    [prefix]: get(
                        state.categories,
                        prefix,
                        List<MetaCategory>()
                    ).filter(value =>
                        isNil(
                            removableCategoryNames.find(
                                categoryName =>
                                    value.metaCategoryId.systemName ===
                                    categoryName
                            )
                        )
                    ),
                },
                fields: {
                    ...state.fields,
                    [prefix]: fields,
                },
            };
        }

        // endregion

        // region field

        case fieldStoreManager().actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ): {
            const fieldLoadSuccess = action as DynamicEntityStoreLoadAllSuccessAction<
                MetaField
            >;
            prefix = extractPrefix(fieldLoadSuccess.additionalData);
            if (isNil(state.fields[prefix])) {
                state.fields[prefix] = Map();
            }
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [prefix]: state.fields[prefix].set(
                        fieldLoadSuccess.subPath,
                        fieldLoadSuccess.items.toList()
                    ),
                },
                [loadingStatusKey]: setIn(
                    state[loadingStatusKey],
                    ['fields', prefix, fieldLoadSuccess.subPath],
                    true
                ),
            };
        }
        case fieldStoreManager().actionType(
            StandardActionTypes.LOAD_ONE_SUCCESS
        ): {
            const fieldAction = action as DynamicEntityStoreLoadOneSuccessAction<
                MetaField
            >;
            prefix = extractPrefix(fieldAction.additionalData);
            if (!state.fields[prefix].has(fieldAction.subPath)) {
                state.fields[prefix].set(fieldAction.subPath, List());
            }
            const metaFields = state.fields[prefix].get(fieldAction.subPath)!;
            const index = metaFields.findIndex(
                value =>
                    value.metaFieldId.systemName ===
                    fieldAction.item.metaFieldId.systemName
            );
            const fields =
                index > -1
                    ? metaFields.set(index, fieldAction.item)
                    : metaFields.push(fieldAction.item);
            if (isNil(state.fields[prefix])) {
                state.fields[prefix] = Map();
            }
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [prefix]: state.fields[prefix].set(
                        fieldAction.subPath,
                        fields
                    ),
                },
            };
        }
        case fieldStoreManager().actionType(
            StandardActionTypes.CREATE_SUCCESS
        ): {
            const addFieldAction = action as DynamicEntityStoreCreateSuccessAction<
                MetaField
            >;
            prefix = extractPrefix(addFieldAction.additionalData);
            const fields = state.fields[prefix].update(
                addFieldAction.subPath,
                List(),
                value => value.push(addFieldAction.item)
            );
            const [currentCategoryIndex, currentCategory] = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findEntry(
                cat => cat.metaCategoryId.systemName === addFieldAction.subPath
            )!;
            const clonedCategory = cloneCategory(currentCategory);
            clonedCategory.metaFieldIds = clonedCategory.metaFieldIds.push(
                addFieldAction.item.metaFieldId
            );
            const categories = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).set(currentCategoryIndex, clonedCategory);
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [prefix]: fields,
                },
                categories: {
                    ...state.categories,
                    [prefix]: categories,
                },
            };
        }
        case fieldStoreManager().actionType(
            StandardActionTypes.UPDATE_MANY_SUCCESS
        ): {
            const updateManyFieldsAction = action as DynamicEntityStoreUpdateManySuccessAction<
                MetaField,
                string
            >;

            prefix = extractPrefix(updateManyFieldsAction.additionalData);
            const updatedItems = updateManyFieldsAction.items
                .toMap()
                .mapKeys((key, value) => value.metaFieldId.systemName);
            const fields = state.fields[prefix].update(
                updateManyFieldsAction.subPath,
                List(),
                value =>
                    value.map(field => {
                        if (updatedItems.has(field.metaFieldId.systemName)) {
                            return cloneEntity(
                                updatedItems.get(field.metaFieldId.systemName)!
                            );
                        } else {
                            return field;
                        }
                    })
            );
            const [currentCategoryIndex, currentCategory] = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findEntry(
                cat =>
                    cat.metaCategoryId.systemName ===
                    updateManyFieldsAction.subPath
            )!;
            const clonedCategory = cloneCategory(currentCategory);
            clonedCategory.keyMetaFieldIds = fields
                .get(updateManyFieldsAction.subPath)!
                .filter(value => value.isKeyField)
                .map(value => value.metaFieldId);
            clonedCategory.nameMetaFieldIds = fields
                .get(updateManyFieldsAction.subPath)!
                .filter(value => value.isName)
                .map(value => value.metaFieldId);
            const categories = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).set(currentCategoryIndex, clonedCategory);
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [prefix]: fields,
                },
                categories: {
                    ...state.categories,
                    [prefix]: categories,
                },
            };
        }

        case fieldStoreManager().actionType(
            StandardActionTypes.DELETE_SUCCESS
        ): {
            const deleteFieldSuccess = action as DynamicEntityStoreDeleteSuccessAction<
                MetaField
            >;
            prefix = extractPrefix(deleteFieldSuccess.additionalData);
            const deleteFieldId =
                deleteFieldSuccess.item.metaFieldId.systemName;
            const categoryIndex = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findIndex(
                category =>
                    category.metaCategoryId.systemName ===
                    deleteFieldSuccess.subPath
            );
            let categories = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            );
            if (categoryIndex >= 0) {
                const clonedCategory = cloneCategory(
                    get(state.categories, prefix, List<MetaCategory>()).get(
                        categoryIndex
                    )!
                );
                clonedCategory.actionDataMetaFieldIds = clonedCategory.actionDataMetaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.keyMetaFieldIds = clonedCategory.keyMetaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.metaFieldIds = clonedCategory.metaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.nameMetaFieldIds = clonedCategory.nameMetaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.searchableFieldIds = clonedCategory.searchableFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.systemMetaFieldIds = clonedCategory.systemMetaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                clonedCategory.transientMetaFieldIds = clonedCategory.transientMetaFieldIds.filter(
                    field => field.systemName !== deleteFieldId
                );
                categories = categories.set(categoryIndex, clonedCategory);
            }
            if (
                deleteFieldSuccess.item.metaFieldType === 'SUB_ENTITY' &&
                notNil(deleteFieldSuccess.item.subEntityMetaCategoryId)
            ) {
                categories = categories.filter(
                    cat =>
                        cat.metaCategoryId.systemName !==
                        deleteFieldSuccess.item.subEntityMetaCategoryId!
                            .systemName
                );
            }

            return {
                ...state,
                categories: {
                    ...state.categories,
                    [prefix]: categories,
                },
                fields: {
                    ...state.fields,
                    [prefix]: state.fields[prefix].set(
                        deleteFieldSuccess.subPath,
                        state.fields[prefix]
                            .get(deleteFieldSuccess.subPath)!
                            .filter(
                                field =>
                                    field.metaFieldId.systemName !==
                                    deleteFieldId
                            )
                    ),
                },
            };
        }

        // endregion

        // region group

        case groupStoreManager().actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ):
            const groupLoadSuccess = action as DynamicEntityStoreLoadAllSuccessAction<
                Group
            >;
            prefix = extractPrefix(groupLoadSuccess.additionalData);

            if (isNil(state.groups[prefix])) {
                state.groups[prefix] = Map();
            }
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [prefix]: state.groups[prefix].set(
                        groupLoadSuccess.subPath,
                        groupLoadSuccess.items.toList()
                    ),
                },
                [loadingStatusKey]: setIn(
                    state[loadingStatusKey],
                    ['groups', prefix, groupLoadSuccess.subPath],
                    true
                ),
            };
        case groupStoreManager().actionType(
            StandardActionTypes.CREATE_SUCCESS
        ): {
            const addGroupAction = action as DynamicEntityStoreCreateSuccessAction<
                Group
            >;
            prefix = extractPrefix(addGroupAction.additionalData);
            const groups = state.groups[prefix].update(
                addGroupAction.subPath,
                List(),
                value => value.push(addGroupAction.item)
            );
            const [currentCategoryIndex, currentCategory] = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findEntry(
                cat => cat.metaCategoryId.systemName === addGroupAction.subPath
            )!;
            const clonedCategory = cloneCategory(currentCategory);
            const categories = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).set(currentCategoryIndex, clonedCategory);
            clonedCategory.groupIds = clonedCategory.groupIds.push({
                systemName: addGroupAction.item.systemName,
                metaCategoryId: clonedCategory.metaCategoryId,
            });
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [prefix]: groups,
                },
                categories: {
                    ...state.categories,
                    [prefix]: categories,
                },
            };
        }
        case groupStoreManager().actionType(
            StandardActionTypes.UPDATE_MANY_SUCCESS
        ): {
            const updateGroupAction = action as DynamicEntityStoreUpdateManySuccessAction<
                Group
            >;
            prefix = extractPrefix(updateGroupAction.additionalData);
            const updatedGroups = updateGroupAction.items
                .toMap()
                .mapKeys((_, v) => v.systemName);
            const groups = state.groups[
                prefix
            ].update(updateGroupAction.subPath, List(), value =>
                value.map(group =>
                    updatedGroups.has(group.systemName)
                        ? updatedGroups.get(group.systemName)!
                        : group
                )
            );
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [prefix]: groups,
                },
            };
        }
        case groupStoreManager().actionType(
            StandardActionTypes.DELETE_SUCCESS
        ): {
            const deleteGroupAction = action as DynamicEntityStoreUpdateSuccessAction<
                Group
            >;
            prefix = extractPrefix(deleteGroupAction.additionalData);
            const groups = state.groups[
                prefix
            ].update(deleteGroupAction.subPath, List(), value =>
                value.filter(
                    group =>
                        group.systemName !== deleteGroupAction.item.systemName
                )
            );
            const [currentCategoryIndex, currentCategory] = get(
                state.categories,
                prefix,
                List<MetaCategory>()
            ).findEntry(
                cat =>
                    cat.metaCategoryId.systemName === deleteGroupAction.subPath
            )!;
            const clonedCategory = cloneCategory(currentCategory);
            clonedCategory.groupIds = clonedCategory.groupIds.filter(
                groupId =>
                    groupId.systemName === deleteGroupAction.item.systemName
            );
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [prefix]: groups,
                },
                categories: {
                    ...state.categories,
                    [prefix]: get(
                        state.categories,
                        prefix,
                        List<MetaCategory>()
                    ).set(currentCategoryIndex, clonedCategory),
                },
            };
        }

        // endregion

        case KbActionTypes.LoadMainEntityFieldNamesSuccess: {
            const actualAction = action as LoadMainEntitiesSuccessAction;
            prefix = actualAction.prefix ? actualAction.prefix : defaultKbKey;
            const categoryName =
                actualAction.mainEntityField.metaCategoryId.systemName;
            const fieldName = actualAction.mainEntityField.systemName;

            if (isNil(state.mainEntityFields[prefix])) {
                state.mainEntityFields[prefix] = Map();
            }

            return {
                ...state,
                mainEntityFields: {
                    ...state.mainEntityFields,
                    [prefix]: state.mainEntityFields[prefix].set(
                        categoryName,
                        state.mainEntityFields[prefix]
                            .get(categoryName, Map<string, Set<string>>())
                            .set(
                                fieldName,
                                actualAction.mainEntityMetaFieldNames
                            )
                    ),
                },
            };
        }

        case KbActionTypes.LoadHierarchicalCategoriesSuccess: {
            const actualAction = action as LoadHierarchicalCategoriesSuccessAction;
            prefix = actualAction.prefix ? actualAction.prefix : defaultKbKey;

            // Do not Optimize
            const data: List<HierarchicalMetaCategoryId> =
                actualAction.hierarchicalMetaCategories;

            return {
                ...state,
                hierarchicalCategories: {
                    ...state.hierarchicalCategories,
                    [prefix]: data,
                },
            };
        }

        case groupStoreManager().actionType(StandardActionTypes.ENTITY_ERROR):
        case fieldStoreManager().actionType(StandardActionTypes.ENTITY_ERROR):
        case categoryStoreManager().actionType(
            StandardActionTypes.ENTITY_ERROR
        ):
            const errorAction = action as {error: string};
            return {
                ...state,
                error: errorAction.error,
            };
        default:
            return state;
    }
}

export function extractPrefix(additionalData: object | undefined): string {
    return additionalData
        ? additionalData['__prefix__']
            ? additionalData['__prefix__']
            : defaultKbKey
        : defaultKbKey;
}
