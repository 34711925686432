import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    AuthenticationService,
    AuthSettings,
    CookieService,
} from '@synisys/idm-authentication-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {AuthenticationSuccess} from '@synisys/skynet-store-authentication-api';
import {SelectLanguageAction} from '@synisys/skynet-store-languages-api';
import {StoreState} from '@synisys/skynet-store-manager';
import {Memoize} from 'lodash-decorators';
import {delay, filter, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {identity} from 'rxjs/util/identity';
import {noop} from 'rxjs/util/noop';
import {createLogger} from './adapter.utillities';

const logger = createLogger('skynet-store-adapter-service');

@Injectable()
export class SkynetStoreAdapterService implements OnDestroy {
    private static _initialized = false;
    private destructionSubject = new Subject<void>();

    constructor(
        private store: Store<StoreState>,
        private authentication: AuthenticationService,
        private cookie: CookieService,
        private currentLanguageProvider: CurrentLanguageProvider
    ) {
        if (SkynetStoreAdapterService._initialized) {
            // throw Error('SkynetStoreAdapterService already initialized');
            logger.warn('SkynetStoreAdapterService already initialized');
        }
        SkynetStoreAdapterService._initialized = true;
        this.init();
    }

    public ngOnDestroy(): void {
        this.destructionSubject.next();
        this.destructionSubject.complete();
    }

    @Memoize()
    private init(): void {
        this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(
                tap(currentLanguage =>
                    this.store.dispatch(
                        new SelectLanguageAction(currentLanguage.getId())
                    )
                ),
                takeUntil(this.destructionSubject)
            )
            .subscribe(noop, err => logger.error('%O', err));

        this.dispatchToken();
        this.authentication
            .isLoggedOn()
            .pipe(
                filter(identity),
                delay(100),
                tap(() => this.dispatchToken()),
                takeUntil(this.destructionSubject)
            )
            .subscribe(noop, err => logger.error('%O', err));
    }

    private dispatchToken(): void {
        const token = this.cookie.get(AuthSettings.TOKEN_KEY_NAME);
        if (token) {
            this.store.dispatch(new AuthenticationSuccess(token));
        } else {
            logger.warn('empty token');
        }
    }
}
