import {
    Language,
    LanguageDirection,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';

export class StoreLanguage extends Language {
    constructor(
        id: number,
        name: MultilingualString,
        acronym: MultilingualString,
        direction: LanguageDirection,
        public isDefault: boolean,
        public charset: string,
        public isInput: boolean
    ) {
        super(id, name, acronym, direction);
    }
}
