import {Injectable, OnDestroy} from '@angular/core';
import {Action, ScannedActionsSubject} from '@ngrx/store';
import {async} from 'rxjs/scheduler/async';

let service: SkynetScannedActionsSubject | undefined;

@Injectable()
export class SkynetScannedActionsSubject extends ScannedActionsSubject
    implements OnDestroy {
    public ngOnDestroy(): void {}

    public next(value: Action): void {
        async.schedule(() => super.next(value));
    }
    public error(value: Error): void {
        async.schedule(() => super.error(value));
    }
    public complete(): void {
        async.schedule(() => super.complete());
    }
}
export function skynetScannedActionsSubjectFactory(): ScannedActionsSubject {
    if (!service) {
        service = new SkynetScannedActionsSubject();
    }
    return service!;
}
