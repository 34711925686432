import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {get} from 'lodash';
import {
    Authenticate,
    AuthenticateIfNecessary,
    AuthenticationActions,
    AuthenticationActionTypes,
    AuthenticationError,
    AuthenticationState,
    AuthenticationSuccess,
} from '@synisys/skynet-store-authentication-api';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../auth.utillities';
import {AuthService} from '../services/auth.service';
import {
    logStoreError,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';

const logger = createLogger('auth-effects');

@Injectable()
export class AuthEffects {
    @Effect()
    public authenticate$: Observable<
        AuthenticationActions
    > = this.actions$.pipe(
        ofType(AuthenticationActionTypes.Authenticate),
        mergeMap((action: Authenticate) => {
            return this.authService
                .login(action.payload.username, action.payload.password)
                .pipe(
                    map(
                        (authDetails: {access_token: string}) =>
                            new AuthenticationSuccess(authDetails.access_token)
                    ),
                    catchError(err => {
                        return of(
                            new AuthenticationError(new StoreManagerError(err))
                        );
                    })
                );
        })
    );

    @Effect()
    public authenticateIfNecessary$: Observable<
        AuthenticationActions
    > = this.actions$.pipe(
        ofType(AuthenticationActionTypes.AuthenticateIfNecessary),
        mergeMap((action: AuthenticateIfNecessary) => {
            return this.store
                .select(state => state.authentication.token)
                .pipe(
                    mergeMap<string | undefined, AuthenticationActions>(
                        token => {
                            if (token === undefined) {
                                return of(new Authenticate(action.payload));
                            } else {
                                return of(new AuthenticationSuccess(token));
                            }
                        }
                    )
                );
        })
    );

    @Effect({dispatch: false})
    public printError: Observable<void> = this.actions$.pipe(
        ofType(AuthenticationActionTypes.AuthenticationError),
        map((action: AuthenticationActions) => {
            if (get(action.payload, 'type') === 'StoreManagerError') {
                logStoreError(<StoreManagerError>action.payload, logger);
            } else {
                logger.error(
                    'error while authenticating error => \n %o',
                    action.payload
                );
            }
        }),
        tap(noop, e => logger.error(e))
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AuthenticationState>,
        private readonly authService: AuthService
    ) {}
}
