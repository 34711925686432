import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {currencyFeatureKey} from '@synisys/skynet-store-currencies-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {CurrencyRateService} from '../services/currency-rate.service';
import {CurrencyService} from '../services/currency.service';
import {CurrencyEffects} from './currency.effects';
import {currenciesReducer} from './currency.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(
            currencyFeatureKey,
            currenciesReducer
        ),
        EffectsModule.forFeature([CurrencyEffects]),
    ],
    providers: [CurrencyService, CurrencyRateService],
})
export class CurrencyStoreModule {}
