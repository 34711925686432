import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {PropertyMap} from '@synisys/skynet-store-properties-api';
import {NullOrUndefined} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {tap} from 'rxjs/operators';
import {createLogger} from '../properties.utilities';

export const SERVICE_URLS_URL = new InjectionToken('Url of Urls');

const logger = createLogger('properties-service');

@Injectable()
export class PropertyService {
    private readonly propertyPath: string;

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(SERVICE_URLS_URL)
        @Optional()
        propertyPath: string | NullOrUndefined
    ) {
        this.propertyPath = isNil(propertyPath)
            ? 'properties/urls'
            : propertyPath;
    }
    public loadProperties(): Observable<PropertyMap> {
        logger.debug('loading properties from %s', this.propertyPath);
        return this.httpClient
            .get<PropertyMap>(this.propertyPath, {
                headers: {'Access-Control-Allow-Origin': '*'},
            })
            .pipe(
                tap(
                    () => logger.debug('successfully loaded properties'),
                    e =>
                        logger.error(
                            'error occurred while loading properties %O',
                            e
                        )
                )
            );
    }
}
