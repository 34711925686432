import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';

// tslint:disable-next-line:no-any
let service: SkynetStore<any> | undefined;

@Injectable()
export class SkynetStore<T> extends Store<T> implements OnDestroy {
    public ngOnDestroy(): void {}
}

export function skynetStoreFactory<T>(
    state$,
    actionsObserver,
    reducerManager
): SkynetStore<T> {
    if (!service) {
        service = new SkynetStore(state$, actionsObserver, reducerManager);
    }
    return service;
}
