import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {CurrencyRateModel} from '@synisys/skynet-store-currencies-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Injectable()
export class CurrencyRateService {
    private readonly currencyUrl$: Observable<string> = getProperties(
        this.store
    ).pipe(
        map(propertyMap => get(propertyMap, 'currency-rate-service-url')),
        assert(
            notNil,
            new StoreManagerError(
                'Url for currency-rate-service-url was not found'
            )
        ),
        publishReplay<string>(1),
        refCount()
    );

    constructor(
        private http: HttpClient,
        private store: Store<PropertiesState>
    ) {}

    public getRate(
        currencyId: number,
        timestamp: number
    ): Observable<CurrencyRateModel> {
        return this.currencyUrl$.pipe(
            switchMap(uri =>
                this.http.get<number>(`${uri}/currencyrates/rate`, {
                    params: {
                        currencyId: currencyId.toString(),
                        timeStamp: timestamp.toString(),
                    },
                })
            ),
            map(rate => ({currencyId, timestamp, rate}))
        );
    }
}
