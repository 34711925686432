export enum StandardActionTypes {
    CREATE = 'create',
    CREATE_SUCCESS = 'create success',
    CREATE_FAIL = 'create fail',
    UPDATE = 'update',
    UPDATE_SUCCESS = 'update success',
    UPDATE_MANY = 'update many',
    UPDATE_MANY_SUCCESS = 'update many success',
    DELETE = 'delete',
    DELETE_SUCCESS = 'delete success',
    LOAD_ALL = 'load all',
    LOAD_MANY_SUCCESS = 'load all success',
    LOAD_ONE = 'load one',
    LOAD_ONE_SUCCESS = 'load one success',
    ENTITY_ERROR = 'entity error',
}
