import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthenticationState} from '@synisys/skynet-store-authentication-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {SERVICE_URLS_URL} from '@synisys/skynet-store-properties-impl';
import {
    assert,
    notNil,
    NullOrUndefined,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {get, isArray, isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError, first, map, mergeMap, tap, timeout} from 'rxjs/operators';
import {TimeoutError} from 'rxjs/util/TimeoutError';
import {createLogger} from './auth.utillities';
import {RequestPatternModel} from './request-pattern.model';

// tslint:disable-next-line:no-any
export type anyRequest = HttpRequest<any>;

export const IGNORE_AUTH_TOKEN = new InjectionToken<RequestPatternModel>(
    'Ignore auth token'
);

const logger = createLogger('auth-interceptor');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public static addToken(request: anyRequest, token: string): anyRequest {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private static isAuthRequest(request: anyRequest, authUrl): boolean {
        return (
            request.method === 'POST' &&
            (request.url.startsWith(`${authUrl}/oauth/token`) ||
                request.url.startsWith(`${authUrl}/oauth/check_token`))
        );
    }

    private readonly token$: Observable<string | undefined>;
    private readonly authUrl$: Observable<string>;

    constructor(
        store: Store<PropertiesState & AuthenticationState>,
        @Inject(SERVICE_URLS_URL)
        @Optional()
        private readonly propertyPath: string | NullOrUndefined,
        @Inject(IGNORE_AUTH_TOKEN)
        @Optional()
        private readonly ignorePatterns: RequestPatternModel[] | NullOrUndefined
    ) {
        this.token$ = store.select(state => state.authentication.token);
        this.authUrl$ = getProperties(store).pipe(
            map(
                propertiesMap =>
                    get(propertiesMap, 'auth-service-url') ||
                    get(propertiesMap, 'oauth-service-url')
            ),
            assert(
                notNil,
                new StoreManagerError('Url for auth-service-url was not found')
            )
        );
    }

    public intercept(
        request: anyRequest,
        next: HttpHandler
    ): Observable<HttpEvent<{}>> {
        if (
            this.isPropertiesRequest(request) ||
            request.headers.has('Authorization') ||
            this.isIgnorable(request)
        ) {
            return next.handle(request);
        }
        return this.authUrl$.pipe(
            first(),
            timeout(2000),
            catchError(err => {
                if (err instanceof TimeoutError) {
                    return ErrorObservable.create(
                        // tslint:disable-next-line:max-line-length
                        `Can't get properties. You need to dispatch action with type PropertiesActionTypes.GetProperties`
                    );
                }
                return ErrorObservable.create(err);
            }),
            mergeMap(authUrl => {
                if (AuthInterceptor.isAuthRequest(request, authUrl)) {
                    return next.handle(request);
                }
                return this.token$.pipe(
                    first(notNil),
                    timeout(1000),
                    catchError(err => {
                        if (err instanceof TimeoutError) {
                            return ErrorObservable.create(`Can't get token`);
                        }
                        return ErrorObservable.create(err);
                    }),
                    tap(t => {
                        if (t === undefined) {
                            logger.warn(
                                'token was undefined while sending %O request',
                                request
                            );
                        }
                    }),
                    first(notNil),
                    mergeMap(token =>
                        next.handle(AuthInterceptor.addToken(request, token))
                    )
                );
            })
        );
    }

    private isPropertiesRequest(request: anyRequest): boolean {
        return (
            request.url.endsWith(
                isNil(this.propertyPath)
                    ? 'app/dummy/properties.json'
                    : this.propertyPath
            ) ||
            request.url.endsWith('properties/urls') ||
            request.url.endsWith('json')
        );
    }

    private isIgnorable(request: anyRequest): boolean {
        if (isNil(this.ignorePatterns)) {
            return false;
        }
        return this.ignorePatterns.some(pattern => pattern.match(request));
    }
}
