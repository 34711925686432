import {Injectable, NgZone} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadOneAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {
    Message,
    MessageKey,
    MessageState,
    messageStoreManager,
} from '@synisys/skynet-store-messages-api';
import {
    enterZone,
    leaveZone,
    logStoreError,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {List, Map, Set} from 'immutable';
import {get, isEmpty, negate} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {
    bufferTime,
    catchError,
    filter,
    first,
    map,
    mergeMap,
    observeOn,
    tap,
} from 'rxjs/operators';
import {async} from 'rxjs/scheduler/async';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../messages.utilities';
import {MessageService} from '../services/message.service';

const logger = createLogger('message-effects');

@Injectable()
export class MessageEffects {
    private static filterMessages(
        loadedMessages: Map<MessageKey, Message>,
        requestedKeys: Set<MessageKey>
    ): Set<MessageKey> {
        const predicate = key => loadedMessages.has(key); // for ngc
        return requestedKeys.filterNot(predicate);
    }

    @Effect()
    public loadMessage$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            Message,
            MessageKey
        >
    > = this.actions$.pipe(
        ofType(messageStoreManager.actionType(StandardActionTypes.LOAD_ONE)),
        bufferTime(1000, -1, 50, leaveZone(this.ngZone, async)),
        observeOn(enterZone(this.ngZone, async)),
        filter(value => value && value.length > 0),
        map((actions: EntityStoreLoadOneAction<MessageKey>[]) =>
            Set(actions.map(action => action.id))
        ),
        mergeMap(actions => {
            return this._loadedMessages$.pipe(
                first(),
                map(messages =>
                    MessageEffects.filterMessages(messages, actions)
                ),
                map(notLoadedKeys => notLoadedKeys.groupBy(k => k.languageId))
            );
        }),
        mergeMap(notLoadedKeysByLanguage => {
            if (!notLoadedKeysByLanguage.isEmpty()) {
                return zip(
                    ...notLoadedKeysByLanguage
                        .entrySeq()
                        .map(([language, keys]) => {
                            return this.messageService.loadMessages(
                                language,
                                keys
                                    .map(key => key.name)
                                    .filter(negate(isEmpty))
                                    .join(',')
                            );
                        })
                        .toArray()
                ).pipe(
                    map((rawMessages: List<Message>[]) => {
                        const tempMap = Map();
                        return tempMap.withMutations(mutable => {
                            rawMessages.forEach(msgs => {
                                msgs.forEach(msg => mutable.set(msg.key, msg));
                            });
                        });
                    }),
                    map((messages: Map<MessageKey, Message>) =>
                        messageStoreManager.createAction(
                            StandardActionTypes.LOAD_MANY_SUCCESS,
                            messages
                        )
                    ),
                    catchError(err => {
                        return of(
                            messageStoreManager.createAction(
                                StandardActionTypes.ENTITY_ERROR,
                                new StoreManagerError(
                                    err,
                                    'could not load messages'
                                )
                            )
                        );
                    })
                );
            } else {
                return of(
                    messageStoreManager.createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        undefined
                    )
                );
            }
        })
    );

    @Effect({dispatch: false})
    public printError = this.actions$.pipe(
        ofType(
            messageStoreManager.actionType(StandardActionTypes.ENTITY_ERROR)
        ),
        map((action: EntityStoreErrorAction) => {
            if (get(action.error, 'type') === 'StoreManagerError') {
                logStoreError(<StoreManagerError>action.error, logger);
            } else {
                logger.error(
                    'error while loading: error => \n %o',
                    action.error
                );
            }
        }),
        tap(noop, e => logger.error(e))
    );

    private _loadedMessages$ = this.store.select(state => state.messages.data);

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<MessageState>,
        private readonly messageService: MessageService,
        private readonly ngZone: NgZone
    ) {}
}
