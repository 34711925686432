import {
    Inject,
    InjectionToken,
    Injector,
    ModuleWithProviders,
    NgModule,
    OnDestroy,
} from '@angular/core';
import {
    _createFeatureReducers,
    _FEATURE_REDUCERS,
    _FEATURE_REDUCERS_TOKEN,
    _initialStateFactory,
    combineReducers,
    FEATURE_REDUCERS,
    ReducerManager,
    STORE_FEATURES,
    StoreRootModule,
} from '@ngrx/store';
import {Action, ActionReducerMap, StoreFeature} from '@ngrx/store/src/models';
import {StoreConfig} from '@ngrx/store/src/store_module';

@NgModule({})
export class SkynetStoreFeatureModule implements OnDestroy {
    public static forFeature<T, V extends Action = Action>(
        featureName: string,
        reducers:
            | ActionReducerMap<T, V>
            | InjectionToken<ActionReducerMap<T, V>>,
        config: StoreConfig<T, V> = {}
    ): ModuleWithProviders {
        return {
            ngModule: SkynetStoreFeatureModule,
            providers: [
                {
                    provide: STORE_FEATURES,
                    multi: true,
                    useValue: {
                        key: featureName,
                        reducerFactory: config.reducerFactory
                            ? config.reducerFactory
                            : combineReducers,
                        metaReducers: config.metaReducers
                            ? config.metaReducers
                            : [],
                        initialState: config.initialState,
                    },
                },
                {provide: _FEATURE_REDUCERS, multi: true, useValue: reducers},
                {
                    provide: _FEATURE_REDUCERS_TOKEN,
                    multi: true,
                    useExisting:
                        reducers instanceof InjectionToken
                            ? reducers
                            : _FEATURE_REDUCERS,
                },
                {
                    provide: FEATURE_REDUCERS,
                    multi: true,
                    deps: [
                        Injector,
                        _FEATURE_REDUCERS,
                        [new Inject(_FEATURE_REDUCERS_TOKEN)],
                    ],
                    useFactory: _createFeatureReducers,
                },
            ],
        };
    }

    constructor(
        @Inject(STORE_FEATURES) private features: StoreFeature<object>[],
        @Inject(FEATURE_REDUCERS)
        private featureReducers: ActionReducerMap<object>[],
        private reducerManager: ReducerManager,
        root: StoreRootModule
    ) {
        features
            .map((feature, index) => {
                const featureReducerCollection = featureReducers.shift();
                const reducers = featureReducerCollection![index];

                return {
                    ...feature,
                    reducers,
                    initialState: _initialStateFactory(feature.initialState),
                };
            })
            .forEach(feature => reducerManager.addFeature(feature));
    }
    public ngOnDestroy(): void {}
}
