import {Store} from '@ngrx/store';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {
    NotifierEventSourceService,
    NotifierService,
} from '@synisys/idm-client-notifier-js';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {get} from 'lodash';
import {empty} from 'rxjs/observable/empty';
import {of} from 'rxjs/observable/of';
import {first, switchMap} from 'rxjs/operators';
import {createLogger} from './logging';

const LOGGER = createLogger('kb-reload.service');

let notifierService: NotifierService;

export function notifierEventSourceServiceFactory(
    store: Store<PropertiesState>
): NotifierService {
    if (!notifierService) {
        // TODO: temporary solution
        // tslint:disable-next-line:max-classes-per-file
        const applicationProperties = new (class extends ApplicationPropertiesService {
            public getBuildVersion(): Promise<string> {
                throw new Error('Not Implemented');
            }

            public getCategoryIconsPath(): Promise<string> {
                throw new Error('Not Implemented');
            }

            public getProperty(): Promise<string> {
                return getProperties(store)
                    .pipe(
                        switchMap(properties => {
                            if (
                                get(properties, 'client-notifier-service-url')
                            ) {
                                return of(
                                    get(
                                        properties,
                                        'client-notifier-service-url'
                                    )
                                );
                            } else {
                                LOGGER.warn(
                                    `Can't find url for client-notifier-service, ` +
                                        `wouldn't automatically fetch changes from kb-service`
                                );
                                return empty<string>();
                            }
                        }),
                        first()
                    )
                    .toPromise();
            }

            public getStaticResourcesUrl(): Promise<string> {
                throw new Error('Not Implemented');
            }
        })();

        notifierService = new NotifierEventSourceService(applicationProperties);
    }
    return notifierService;
}
