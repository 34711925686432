import {
    MultilingualString,
    MultilingualStringBuilder,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {List} from 'immutable';
import {
    entries,
    isNil,
    isPlainObject,
    isString,
    mapValues,
    set,
    isEmpty,
} from 'lodash';
import {MultilingualStringDto, NullOrUndefined} from './models/dto.models';

export function deserializeMultilingualString(
    dto: MultilingualStringDto
): MultilingualString {
    const builder: MultilingualStringBuilder = MultilingualString.newBuilder();
    Object.getOwnPropertyNames(dto).forEach(languageId =>
        builder.withValueForLanguage(Number(languageId), dto[languageId])
    );
    return builder.build();
}

export function serializeMultilingualString(
    multilingualString: MultilingualString
): MultilingualStringDto {
    const dto: MultilingualStringDto = {};
    multilingualString.toMap().forEach((value, key) => {
        set(dto, key, value);
    });
    return dto;
}

export function deserializeIfMultilingual<T>(obj: T): MultilingualString | T {
    if (!isNil(obj) && obj.hasOwnProperty('_values')) {
        return entries(<{[languageId: number]: string}>obj['_value'])
            .reduce((previousValue, currentValue) => {
                return previousValue.withValueForLanguage(
                    Number(currentValue[0]),
                    currentValue[1]
                );
            }, MultilingualString.newBuilder())
            .build();
    } else {
        return obj;
    }
}

export function deserializeArrays(obj: object): {[key: string]: {}} {
    return <{[key: string]: {}}>mapValues(obj, value => {
        if (isPlainObject(value)) {
            return deserializeArrays(value);
        } else if (Array.isArray(value)) {
            return List(value.map(item => deserializeArrays(item)));
        } else {
            return value;
        }
    });
}

export type deserializableMultilingual =
    | MultilingualStringDto
    | MultilingualString
    | object
    | string
    | NullOrUndefined;
export function deserializeMultilingual(
    hint: deserializableMultilingual
): MultilingualString | undefined {
    if (isNil(hint)) {
        return undefined;
    }
    if (hint instanceof MultilingualString) {
        return hint;
    }
    if (isString(hint)) {
        return stringToMultilingual(hint);
    }
    if (isPlainObject(hint)) {
        const builder = MultilingualString.newBuilder();
        entries(hint).forEach(([key, value]) => {
            builder.withValueForLanguage(parseInt(key, 10), value);
        });
        return builder.build();
    }
    return undefined;
}

export function stringToMultilingual(
    json: string
): MultilingualString | undefined {
    if (json === '') {
        return undefined;
    }
    const multilingualString = JSON.parse(json);
    const builder = MultilingualString.newBuilder();
    for (const key in multilingualString) {
        if (multilingualString.hasOwnProperty(key)) {
            builder.withValueForLanguage(+key, multilingualString[key]);
        }
    }
    return builder.build();
}

export function isEmptyMultilingual(
    multilingual: MultilingualString | NullOrUndefined
): boolean {
    return (
        isNil(multilingual) ||
        isEmpty(multilingual) ||
        isNil(multilingual.toMap) ||
        isEmpty(multilingual.toMap())
    );
}
