import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    ENTITY_MULTILINGUAL_NAME_ATTRIBUTE,
    MetaCategory,
    MetaCategoryDto,
    SavableMetaCategoryDto,
} from '@synisys/skynet-store-kb-api';
import {
    deserializableMultilingual,
    deserializeIfMultilingual,
    deserializeMultilingual,
    serializeMultilingualString,
} from '@synisys/skynet-store-utilities';
import {hash, List, ValueObject} from 'immutable';
import {mapValues} from 'lodash';
import {ExcludeParent, makeValueObject, toLower} from '../kb.utilities';

export function deserializeMetaCategories(
    values: MetaCategoryDto[]
): List<MetaCategory> {
    return List(values).map(createMetaCategory);
}

export function createMetaCategory(category: MetaCategoryDto): MetaCategory {
    const metaCategory = <ExcludeParent<MetaCategory, ValueObject>>(
        mapValues(category, (value, key) => {
            if (Array.isArray(value)) {
                return List(value as object[]);
            } else if (key === ENTITY_MULTILINGUAL_NAME_ATTRIBUTE) {
                return deserializeMultilingual(
                    value as deserializableMultilingual
                );
            } else {
                return deserializeIfMultilingual(value);
            }
        })
    );
    return makeValueObject(
        metaCategory,
        (first, other) =>
            toLower(first.metaCategoryId.systemName) ===
            toLower(other.metaCategoryId.systemName),
        obj => hash(obj.metaCategoryId.systemName)
    );
}

export function serializeMetaCategory(category: MetaCategory): MetaCategoryDto {
    return <MetaCategoryDto>(
        mapValues<object, MetaCategoryDto>(category, (value, key) => {
            if (List.isList(value)) {
                return value.toJS();
            } else if (key === ENTITY_MULTILINGUAL_NAME_ATTRIBUTE) {
                return serializeMultilingualString(value as MultilingualString);
            } else {
                return value;
            }
        })
    );
}

export function convertCategoryToSavable(
    category: MetaCategoryDto
): SavableMetaCategoryDto {
    return {
        displayName: category.displayName,
        displayNameMultilingual: category.displayNameMultilingual,
        hasCustomSorting: category.hasCustomSorting,
        isRoot: category.isRoot,
        hasIcon: category.hasIcon,
        iconId: category.iconId,
        isCacheable: category.isCacheable,
        isChild: category.isChild,
        isClassifier: category.isClassifier,
        isSearchable: category.isSearchable,
        isWithEditableForm: category.isWithEditableForm,
        isWithVersioning: category.isWithVersioning,
        showInCategoryManager: category.showInCategoryManager,
        systemName: category.metaCategoryId.systemName,
        tableName: category.tableName,
        wfProcessId: category.wfProcessId,
        sortInfo: category.sortInfo,
        fields: [],
    };
}
