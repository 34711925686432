import {Injectable, OnDestroy} from '@angular/core';
import {State} from '@ngrx/store';
import {async} from 'rxjs/scheduler/async';

let service: SkynetState<object> | undefined;

@Injectable()
export class SkynetState<T> extends State<T> implements OnDestroy {
    public ngOnDestroy(): void {}

    public next(value: T): void {
        async.schedule(() => super.next(value));
    }
    public error(value: Error): void {
        async.schedule(() => super.error(value));
    }
    public complete(): void {
        async.schedule(() => super.complete());
    }
}

export function skynetStateFactory<T>(
    actions$,
    reducer$,
    scannedActions,
    initialState
): State<T> {
    if (!service) {
        service = new SkynetState(
            actions$,
            reducer$,
            scannedActions,
            initialState
        );
    }
    return service;
}
