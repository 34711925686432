import {Injectable, OnDestroy} from '@angular/core';
import {ɵf as EffectsRunner} from '@ngrx/effects';

let service: SkynetEffectsRunner | undefined;

@Injectable()
export class SkynetEffectsRunner extends EffectsRunner implements OnDestroy {
    public ngOnDestroy(): void {}
}

export function skynetEffectsRunnerFactory(
    effectSources,
    store
): SkynetEffectsRunner {
    if (!service) {
        service = new SkynetEffectsRunner(effectSources, store);
    }
    return service;
}
