import {Map} from 'immutable';
import {Message, MessageKey} from './model/message.model';

export const messagesFeatureKey = 'messages';

export interface MessageState {
    [messagesFeatureKey]: {
        data: Map<MessageKey, Message>;
        error: string | Error | undefined;
    };
}
