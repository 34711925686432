import {AuthenticationError} from './actions/authentication-error.action';
import {AuthenticateIfNecessary} from './actions/authenticate-if-necessary.action';
import {AuthenticationSuccess} from './actions/authentication-success.action';
import {StoreManagerError} from '@synisys/skynet-store-utilities';
import {Authenticate} from './actions/authenticate.action';

export type AuthenticationActions =
    | Authenticate
    | AuthenticateIfNecessary
    | AuthenticationSuccess
    | AuthenticationError;

export type AuthPayload = {
    username: string;
    password: string;
    application: string;
};

export const authenticationFeatureKey = 'authentication';

export interface AuthenticationState {
    [authenticationFeatureKey]: {
        token: string | undefined;
        error: string | undefined | StoreManagerError;
    };
}
