import {
    AuthenticationActions,
    AuthenticationActionTypes,
    AuthenticationError,
    authenticationFeatureKey,
    AuthenticationState,
    AuthenticationSuccess,
} from '@synisys/skynet-store-authentication-api';
import {StoreManagerError} from '@synisys/skynet-store-utilities';

const initialState: AuthenticationState[typeof authenticationFeatureKey] = {
    token: undefined,
    error: undefined,
};

export function authenticationReducer(
    state = initialState,
    action: AuthenticationActions
): AuthenticationState[typeof authenticationFeatureKey] {
    // tslint:disable-next-line:no-var-keyword
    switch (action.type) {
        case AuthenticationActionTypes.AuthenticationSuccess: {
            return {
                ...state,
                token: (action as AuthenticationSuccess).payload as string,
            };
        }
        case AuthenticationActionTypes.AuthenticationError: {
            return {
                ...state,
                error: (action as AuthenticationError)
                    .payload as StoreManagerError,
            };
        }
        default: {
            return state;
        }
    }
}
