import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    CustomLanguageActions,
    CustomLanguageActionTypes,
    LanguagesState,
    languageStateKey,
    languageStoreManager,
    SelectLanguageAction,
    StoreLanguage,
} from '@synisys/skynet-store-languages-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadAllSuccessAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {List} from 'immutable';

export function languagesReducer(
    state: LanguagesState[typeof languageStateKey] = {
        languages: List(),
        currentLanguageId: undefined,
        error: undefined,
    },
    action:
        | EntityActionTypes<StandardActionTypes, Language, number>
        | CustomLanguageActions
): LanguagesState[typeof languageStateKey] {
    switch (action.type) {
        case languageStoreManager.actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ):
            return {
                ...state,
                languages: (action as EntityStoreLoadAllSuccessAction<
                    StoreLanguage
                >).items.toList(),
            };
        case languageStoreManager.actionType(StandardActionTypes.ENTITY_ERROR):
            return {
                ...state,
                error: (<EntityStoreErrorAction>action).error,
            };
        case CustomLanguageActionTypes.SelectLanguage:
            return {
                ...state,
                currentLanguageId: (<SelectLanguageAction>action).languageId,
            };
        default:
            return state;
    }
}
