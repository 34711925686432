import {NgZone} from '@angular/core';
import {IScheduler, Scheduler} from 'rxjs/Scheduler';
import {Subscription} from 'rxjs/Subscription';
import {Action} from 'rxjs/scheduler/Action';

export class EnterZoneScheduler implements IScheduler {
    constructor(private zone: NgZone, private scheduler: Scheduler) {}

    public schedule<T>(
        work: (this: Action<T>, state?: T) => void,
        delay: number = 0,
        state?: T
    ): Subscription {
        return this.zone.run(() =>
            this.scheduler.schedule.apply(this.scheduler, [work, delay, state])
        );
    }

    public now(): number {
        return this.scheduler.now();
    }
}

export function enterZone(zone: NgZone, scheduler: Scheduler): IScheduler {
    return new EnterZoneScheduler(zone, scheduler);
}
