import {Store} from '@ngrx/store';
import {notNil} from '@synisys/skynet-store-utilities';
import {Observable} from 'rxjs/Observable';
import {filter, map} from 'rxjs/operators';
import {memoize, MemoizedFunction} from 'lodash';
import {PropertiesState, PropertyMap} from './properties.state';

export const getProperties: MemoizedFunction & typeof loadProperties = memoize(
    loadProperties,
    () => 'key'
);

export function loadProperties(
    store: Store<PropertiesState>
): Observable<PropertyMap> {
    return store
        .select(state => state && state.properties)
        .pipe(
            filter(notNil),
            map(properties => properties.map),
            filter(notNil)
        );
}
