import {Injectable, OnDestroy} from '@angular/core';
import {ReducerManager} from '@ngrx/store';

let service: SkynetReducerManager | undefined;

@Injectable()
export class SkynetReducerManager extends ReducerManager implements OnDestroy {
    public ngOnDestroy(): void {}
}

export function skynetReducerManagerFactory(
    dispatcher,
    initialState,
    reducers,
    reducerFactory
): SkynetReducerManager {
    if (!service) {
        service = new SkynetReducerManager(
            dispatcher,
            initialState,
            reducers,
            reducerFactory
        );
    }
    return service!;
}
