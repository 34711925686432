import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    ENTITY_MULTILINGUAL_NAME_ATTRIBUTE,
    Group,
    GroupInfo,
} from '@synisys/skynet-store-kb-api';
import {
    deserializeIfMultilingual,
    deserializeMultilingual,
    NullOrUndefined,
    serializeMultilingualString,
} from '@synisys/skynet-store-utilities';
import {hash, List, ValueObject} from 'immutable';
import {mapValues} from 'lodash';
import {ExcludeParent, makeValueObject, toLower} from '../kb.utilities';

export function serializeGroup(category: Group): GroupInfo {
    return <GroupInfo>mapValues<object, GroupInfo>(category, (value, key) => {
        if (key === ENTITY_MULTILINGUAL_NAME_ATTRIBUTE) {
            return serializeMultilingualString(value as MultilingualString);
        }
        return value;
    });
}

export function deserializeGroups(values: GroupInfo[]): List<Group> {
    return List(values).map(createGroup);
}

export function createGroup(value: GroupInfo): Group {
    const group = <ExcludeParent<Group, ValueObject>>(
        mapValues(value, (it, key) => {
            if (key === ENTITY_MULTILINGUAL_NAME_ATTRIBUTE) {
                return deserializeMultilingual(
                    it as MultilingualString | NullOrUndefined
                );
            }
            return deserializeIfMultilingual(it);
        })
    );

    return makeValueObject(
        group,
        (first, other) =>
            toLower(first.systemName) === toLower(other.systemName) &&
            toLower(first.metaCategoryId.systemName) ===
                toLower(other.metaCategoryId.systemName),
        obj => hash(obj.systemName) * 31 + hash(obj.metaCategoryId.systemName)
    );
}
