import {loadingStatusKey} from '@synisys/skynet-store-manager';
import {List, Map, Set} from 'immutable';
import {HierarchicalMetaCategoryId} from './kb.models';
import {MetaCategory} from './model/category.models';
import {MetaField} from './model/field.models';
import {Group} from './model/group.models';

export const kbFeatureKey = 'kb';
export const defaultKbKey = 'de';

export interface KbState {
    [kbFeatureKey]: {
        fields: {
            [defaultKbKey]: Map<string, List<MetaField>>;
            [prefixedKbKey: string]: Map<string, List<MetaField>>;
        };
        categories: {
            [defaultKbKey]: List<MetaCategory> | undefined;
            [prefixedKbKey: string]: List<MetaCategory> | undefined;
        };
        groups: {
            [defaultKbKey]: Map<string, List<Group>>;
            [prefixedKbKey: string]: Map<string, List<Group>>;
        };
        mainEntityFields: {
            // category name to field name to main entity field system names
            [defaultKbKey]: Map<string, Map<string, Set<string>>>;
            [prefixedKbKey: string]: Map<string, Map<string, Set<string>>>;
        };
        hierarchicalCategories: {
            [defaultKbKey]: List<HierarchicalMetaCategoryId> | undefined;
            [prefixedKbKey: string]:
                | List<HierarchicalMetaCategoryId>
                | undefined;
        };
        error?: string | undefined;
        [loadingStatusKey]: {
            categories?: {
                [prefixedKbKey: string]: boolean;
            };
            fields?: {
                [prefixedKbKey: string]: {
                    [key: string]: boolean;
                };
            };
            groups?: {
                [prefixedKbKey: string]: {
                    [key: string]: boolean;
                };
            };
        };
    };
}
