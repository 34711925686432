import {DynamicEntityStoreManager} from '@synisys/skynet-store-manager';
import {isNil} from 'lodash';
import {defaultKbKey} from './kb.state';
import {CategoryStoreManager} from './manager/category-store.manager';
import {FieldStoreManager} from './manager/field-store.manager';
import {Group, GroupInfo} from './model/group.models';

const categoryStoreManagerFactory = function(): (
    prefix?: string
) => CategoryStoreManager {
    const cache: {
        [key: string]: CategoryStoreManager;
    } = {};

    return (prefix?: string): CategoryStoreManager => {
        const actualPrefix = isNil(prefix) ? defaultKbKey : prefix;

        if (isNil(cache[actualPrefix])) {
            cache[actualPrefix] = new CategoryStoreManager(
                `kb.categories`,
                item => item.metaCategoryId.systemName.toLowerCase(),
                actualPrefix
            );
        }

        return cache[actualPrefix];
    };
};

export const categoryStoreManager = categoryStoreManagerFactory();

const fieldStoreManagerFactory = function(): (
    prefix?: string
) => FieldStoreManager {
    const cache: {
        [key: string]: FieldStoreManager;
    } = {};

    return (prefix?: string): FieldStoreManager => {
        const actualPrefix = isNil(prefix) ? defaultKbKey : prefix;

        if (isNil(cache[actualPrefix])) {
            cache[actualPrefix] = new FieldStoreManager(
                `kb.fields`,
                item => item.metaFieldId.systemName.toLowerCase(),
                actualPrefix
            );
        }

        return cache[actualPrefix];
    };
};

export const fieldStoreManager = fieldStoreManagerFactory();

const groupStoreManagerFactory = function(): (
    prefix?: string
) => DynamicEntityStoreManager<Group, string, GroupInfo> {
    const cache: {
        [key: string]: DynamicEntityStoreManager<Group, string, GroupInfo>;
    } = {};

    return (
        prefix?: string
    ): DynamicEntityStoreManager<Group, string, GroupInfo> => {
        const actualPrefix = isNil(prefix) ? defaultKbKey : prefix;

        if (isNil(cache[actualPrefix])) {
            cache[actualPrefix] = new DynamicEntityStoreManager(
                `kb.groups`,
                item => item.systemName.toLowerCase(),
                actualPrefix
            );
        }

        return cache[actualPrefix];
    };
};

export const groupStoreManager = groupStoreManagerFactory();
