import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {languageStateKey} from '@synisys/skynet-store-languages-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {LanguageService} from '../services/language.service';
import {LanguageEffects} from './language.effects';
import {languagesReducer} from './language.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(languageStateKey, languagesReducer),
        EffectsModule.forFeature([LanguageEffects]),
    ],
    providers: [LanguageService],
})
export class LanguagesStoreModule {}
