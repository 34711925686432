import {Map} from 'immutable';
import {CurrencyModel} from './currency.models';
import {CurrencyRateModel} from './currency-rate.models';

export const currencyFeatureKey = 'currencies';

export interface CurrencyState {
    [currencyFeatureKey]: {
        currencies: Map<number, CurrencyModel>;
        rates: Map<string, CurrencyRateModel>;
        error: string | Error | undefined;
    };
}
