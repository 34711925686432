import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {LocaleInfo} from '@synisys/skynet-store-locales-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {get, memoize} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {identity} from 'rxjs/util/identity';

@Injectable()
export class LocaleService {
    public getLocaleInfo: (num: number) => Observable<LocaleInfo> = memoize(
        this._getLocaleInfo,
        identity
    );

    private localeUrl$: Observable<string> = getProperties(this.store).pipe(
        map(propertyMap =>
            get(
                propertyMap,
                'locale-service-url',
                get(propertyMap, 'ml-service-url')
            )
        ),
        assert(
            notNil,
            new StoreManagerError('Url for locale-service was not found')
        ),
        publishReplay<string>(1),
        refCount()
    );

    constructor(
        private http: HttpClient,
        private store: Store<PropertiesState>
    ) {}

    private _getLocaleInfo(languageId: number): Observable<LocaleInfo> {
        return this.localeUrl$.pipe(
            switchMap(url =>
                this.http.get<LocaleInfo>(
                    `${url}/languages/${languageId}/locale-info`
                )
            ),
            publishReplay<LocaleInfo>(1),
            refCount()
        );
    }
}
