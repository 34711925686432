import {
    currencyFeatureKey,
    CurrencyModel,
    CurrencyRateModel,
    currencyRateStoreManager,
    CurrencyState,
    currencyStoreManager,
    keyExtractor,
} from '@synisys/skynet-store-currencies-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadAllSuccessAction,
    EntityStoreLoadOneSuccessAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {Map} from 'immutable';

export function currenciesReducer(
    state: CurrencyState[typeof currencyFeatureKey] = {
        currencies: Map<number, CurrencyModel>(),
        rates: Map<string, CurrencyRateModel>(),
        error: undefined,
    },
    action: EntityActionTypes<
        StandardActionTypes,
        CurrencyModel | CurrencyRateModel,
        number
    >
): CurrencyState[typeof currencyFeatureKey] {
    switch (action.type) {
        case currencyStoreManager.actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ):
            const items = (action as EntityStoreLoadAllSuccessAction<
                CurrencyModel
            >).items;
            if (items === undefined) {
                return state;
            }
            return {
                ...state,
                currencies: state.currencies.withMutations(mutable => {
                    items.forEach(value => mutable.set(value.id, value));
                }),
            };

        case currencyRateStoreManager.actionType(
            StandardActionTypes.LOAD_ONE_SUCCESS
        ):
            const item = (action as EntityStoreLoadOneSuccessAction<
                CurrencyRateModel
            >).item;
            if (item === undefined) {
                return state;
            }
            return {
                ...state,
                rates: state.rates.withMutations(mutable =>
                    mutable.set(keyExtractor(item), item)
                ),
            };

        case currencyStoreManager.actionType(StandardActionTypes.ENTITY_ERROR):
        case currencyRateStoreManager.actionType(
            StandardActionTypes.ENTITY_ERROR
        ):
            return {
                ...state,
                error: (action as EntityStoreErrorAction).error,
            };
        default:
            return state;
    }
}
