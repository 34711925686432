import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    LocaleInfo,
    LocaleState,
    localeStoreManager,
} from '@synisys/skynet-store-locales-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadOneAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {
    logStoreError,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {Map} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError, first, map, mergeMap, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../locales.utilities';
import {LocaleService} from '../services/locale.service';

const logger = createLogger('locale-effects');

@Injectable()
export class LocaleEffects {
    @Effect()
    public readonly loadLocale$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_ONE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            LocaleInfo,
            number
        >
    > = this.actions$.pipe(
        ofType(localeStoreManager.actionType(StandardActionTypes.LOAD_ONE)),
        mergeMap((action: EntityStoreLoadOneAction<number>) => {
            return this.store
                .select(state => state.locales.data)
                .pipe(
                    first(),
                    mergeMap((localesMap: Map<number, LocaleInfo>) => {
                        if (localesMap.has(action.id)) {
                            return of(
                                localeStoreManager.createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    localesMap.get(action.id)!,
                                    {
                                        languageId: action.id,
                                    }
                                )
                            );
                        }
                        logger.debug(
                            'requesting locale service to load locales'
                        );
                        return this.localService.getLocaleInfo(action.id).pipe(
                            tap(() => (this.localeChanged = true)),
                            map(locale =>
                                localeStoreManager.createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    locale,
                                    {
                                        languageId: action.id,
                                    }
                                )
                            ),
                            catchError(err => {
                                return of(
                                    localeStoreManager.createAction(
                                        StandardActionTypes.ENTITY_ERROR,
                                        new StoreManagerError(
                                            err,
                                            'could not load locale'
                                        )
                                    )
                                );
                            })
                        );
                    })
                );
        })
    );

    @Effect({dispatch: false})
    public printError = this.actions$.pipe(
        ofType(localeStoreManager.actionType(StandardActionTypes.ENTITY_ERROR)),
        map((action: EntityStoreErrorAction) => {
            if (get(action.error, 'type') === 'StoreManagerError') {
                logStoreError(<StoreManagerError>action.error, logger);
            } else {
                logger.error(
                    'error while loading: error => \n %o',
                    action.error
                );
            }
        }),
        tap(noop, e => logger.error(e))
    );

    private localeChanged = false;

    constructor(
        private readonly actions$: Actions,
        private readonly localService: LocaleService,
        private readonly store: Store<LocaleState>
    ) {}
}
