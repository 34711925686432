import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {kbFeatureKey} from '@synisys/skynet-store-kb-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {MessageStoreModule} from '@synisys/skynet-store-messages-impl';
import {KbReloadModule} from '../kb-reload/_kb-reload.module';
import {KbService} from '../services/kb.service';
import {KbEffects} from './kb.effects';
import {kbReducer} from './kb.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(kbFeatureKey, kbReducer),
        EffectsModule.forFeature([KbEffects]),
        KbReloadModule,
        MessageStoreModule,
    ],
    providers: [KbService],
})
export class KbStoreModule {}
