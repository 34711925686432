import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {Store, StoreModule} from '@ngrx/store';
import {languageStoreManager} from '@synisys/skynet-store-languages-api';
import {StandardActionTypes} from '@synisys/skynet-store-manager';
import {
    PropertiesActionTypes,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {Memoize} from 'lodash-decorators';
import {SkynetStoreAdapterService} from './skynet-store-adapter.service';
import {SkynetStoreModule} from './skynet-store.module';

@NgModule({
    exports: [EffectsModule, StoreModule],
    imports: [SkynetStoreModule],
    providers: [SkynetStoreAdapterService],
})
export class SkynetStoreAdapterModule {
    public static forRoot(): typeof SkynetStoreAdapterModule {
        if (SkynetStoreAdapterModule._imported) {
            throw Error('SkynetStoreAdapterModule already imported');
        }
        SkynetStoreAdapterModule._imported = true;
        return SkynetStoreAdapterModule;
    }

    private static _imported = false;

    constructor(
        private skynetStoreAdapterService: SkynetStoreAdapterService, // for init
        private store: Store<PropertiesState>
    ) {
        this.init();
    }

    @Memoize()
    private init(): void {
        this.store.dispatch({type: PropertiesActionTypes.GetProperties});
        languageStoreManager.selectAll(this.store);
    }
}
