import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
    AuthInterceptor,
    AuthStoreModule,
} from '@synisys/skynet-store-authentication-impl';
import {LanguagesStoreModule} from '@synisys/skynet-store-languages-impl';
import {PropertiesStoreModule} from '@synisys/skynet-store-properties-impl';
import {SkynetEffectsRootModule} from './store-root/skynet-effects-root.module';
import {SkynetStoreRootModule} from './store-root/skynet-store-root.module';

@NgModule({
    exports: [EffectsModule, StoreModule],
    imports: [
        SkynetStoreRootModule.forRoot(),
        SkynetEffectsRootModule.forRoot(),
        PropertiesStoreModule,
        AuthStoreModule,
        LanguagesStoreModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class SkynetStoreModule {}
