import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {LanguagesStoreModule} from '@synisys/skynet-store-languages-impl';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {messagesFeatureKey} from '@synisys/skynet-store-messages-api';
import {MessageService} from '../services/message.service';
import {MessageEffects} from './message.effects';
import {messagesReducer} from './message.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(
            messagesFeatureKey,
            messagesReducer
        ),
        EffectsModule.forFeature([MessageEffects]),
        LanguagesStoreModule,
    ],
    providers: [MessageService],
})
export class MessageStoreModule {}
