import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    GetPropertiesError,
    GetPropertiesSuccess,
    PropertiesActions,
    PropertiesActionTypes,
    PropertiesState,
    PropertyMap,
} from '@synisys/skynet-store-properties-api';
import {
    logStoreError,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {get, isEqual, isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {
    catchError,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../properties.utilities';
import {PropertyService} from '../services/property.service';

const logger = createLogger('properties-effects');

@Injectable()
export class PropertiesEffects {
    @Effect()
    public readonly loadProperties$: Observable<
        PropertiesActions
    > = this.actions$.pipe(
        ofType(PropertiesActionTypes.GetProperties),
        tap(() => logger.debug('received action to load properties')),
        switchMap(() => {
            return this.store.pipe(
                map(state => state.properties.map),
                distinctUntilChanged(
                    (propertyMapX: PropertyMap, propertyMapY: PropertyMap) => {
                        if (isNil(propertyMapY) && isNil(propertyMapX)) {
                            return true;
                        } else if (isNil(propertyMapY) || isNil(propertyMapX)) {
                            return false;
                        } else {
                            return isEqual(propertyMapY, propertyMapX);
                        }
                    }
                ),
                switchMap((propertyMap: PropertyMap) => {
                    if (propertyMap === undefined) {
                        logger.debug(
                            'requesting property service to load properties'
                        );
                        return this.propertyService.loadProperties().pipe(
                            map(
                                properties =>
                                    new GetPropertiesSuccess(<PropertyMap>(
                                        properties
                                    ))
                            ),
                            catchError((err, caught) => {
                                return of(
                                    new GetPropertiesError(
                                        new StoreManagerError(
                                            err,
                                            'could not load properties'
                                        )
                                    )
                                );
                            })
                        );
                    } else {
                        return of(new GetPropertiesSuccess(propertyMap));
                    }
                })
            );
        })
    );

    @Effect({dispatch: false})
    public printError = this.actions$.pipe(
        ofType(PropertiesActionTypes.GetPropertiesError),
        map((action: GetPropertiesError) => {
            if (get(action.payload, 'type') === 'StoreManagerError') {
                logStoreError(<StoreManagerError>action.payload, logger);
            } else {
                logger.error(
                    'error while loading: error => \n %o',
                    action.payload
                );
            }
        }),
        tap(noop, e => logger.error(e))
    );

    constructor(
        private readonly actions$: Actions,
        private readonly propertyService: PropertyService,
        private readonly store: Store<PropertiesState>
    ) {}
}
