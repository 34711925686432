import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    defaultKbKey,
    EntityWithName,
    Group,
    GroupInfo,
    HierarchicalMetaCategoryId,
    MetaCategory,
    MetaCategoryDto,
    MetaField,
    MetaFieldDto,
    SavableMetaCategoryDto,
    SavableMetaFieldDto,
} from '@synisys/skynet-store-kb-api';
import {languageStoreManager} from '@synisys/skynet-store-languages-api';
import {
    Message,
    MessageKey,
    MessageKeyFactory,
    MessageState,
    messageStoreManager,
} from '@synisys/skynet-store-messages-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    isEmptyMultilingual,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {List, Seq, Set} from 'immutable';
import {get, isNil, isEmpty} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {filter, map, switchMap} from 'rxjs/operators';
import {
    createMetaCategory,
    deserializeMetaCategories,
} from '../serialization/category.serializer';
import {
    createMetaField,
    deserializeMetaFields,
} from '../serialization/field.serializer';
import {
    createGroup,
    deserializeGroups,
} from '../serialization/group.serializer';

@Injectable()
export class KbService {
    private readonly $kbUrl = getProperties(this.store).pipe(
        map(properties => get(properties, 'kb-service-url')),
        assert(
            notNil,
            new StoreManagerError('Url for kb-service-url was not found')
        )
    );

    constructor(
        private readonly http: HttpClient,
        private readonly store: Store<PropertiesState & MessageState>
    ) {}

    // region categories

    public loadCategories(prefix: string): Observable<List<MetaCategory>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(prefix)}categories`
                    )
                    .pipe(map(deserializeMetaCategories))
            ),
            switchMap(categories => {
                if (categories.isEmpty()) {
                    return of([]);
                }
                return zip(
                    ...categories
                        .map(category => this.correctNameOfEntity(category))
                        .toArray()
                );
            }),
            map(categories => List(categories))
        );
    }

    public loadCategory(
        categoryName: string,
        prefix: string
    ): Observable<MetaCategory> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}categories/${categoryName}`
                    )
                    .pipe(map(createMetaCategory))
            ),
            switchMap(category => {
                return this.correctNameOfEntity(category);
            })
        );
    }

    public createCategory(
        category: SavableMetaCategoryDto,
        prefix: string
    ): Observable<MetaCategory> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.post<MetaCategoryDto>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}categories?updateDbStructure=true`,
                    category
                )
            ),
            map(createMetaCategory)
        );
    }

    public updateCategory(
        category: SavableMetaCategoryDto,
        prefix: string
    ): Observable<MetaCategory> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.patch<MetaCategoryDto>(
                    `${url}/${this.urlCorrectionOnPrefix(prefix)}categories/${
                        category.systemName
                    }?updateDbStructure=true`,
                    category
                )
            ),
            map(createMetaCategory)
        );
    }

    public deleteCategory(
        category: MetaCategory,
        prefix: string
    ): Observable<MetaCategory> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.delete(
                    `${url}/${this.urlCorrectionOnPrefix(prefix)}categories/${
                        category.metaCategoryId.systemName
                    }?updateDbStructure=true`
                )
            ),
            map(() => category)
        );
    }

    public loadHierarchicalCategories(
        prefix?: string
    ): Observable<List<HierarchicalMetaCategoryId>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}hierarchicalcategories`
                    )
                    .pipe(
                        map((fieldDtos: HierarchicalMetaCategoryId[]) =>
                            List(fieldDtos)
                        )
                    )
            )
        );
    }

    // endregion

    // region fields

    public updateFields(
        categoryName: string,
        metaFields: List<SavableMetaFieldDto>,
        prefix: string
    ): Observable<List<MetaField>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.patch<MetaFieldDto[]>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/fields?updateDbStructure=true`,
                    metaFields
                        .toSeq()
                        .groupBy(metaField => {
                            if (notNil(metaField.systemName)) {
                                return metaField.systemName;
                            } else {
                                throw new TypeError(
                                    'trying to save meta-field without system name'
                                );
                            }
                        })
                        .map(value => value.get(0))
                        .toJS()
                )
            ),
            map(metaFieldDtos => List(metaFieldDtos.map(createMetaField)))
        );
    }

    public updateField(
        categoryName: string,
        metaField: SavableMetaFieldDto,
        prefix: string
    ): Observable<MetaField> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.patch<MetaFieldDto[]>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/fields?updateDbStructure=true`,
                    {
                        [metaField.systemName]: [metaField],
                    }
                )
            ),
            map(([fieldDto]) => createMetaField(fieldDto))
        );
    }

    public deleteField(
        metaField: MetaField,
        prefix: string
    ): Observable<MetaField> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.request(
                    'DELETE',
                    `${url}/${this.urlCorrectionOnPrefix(prefix)}${
                        metaField.metaFieldId.metaCategoryId.systemName
                    }/fields?updateDbStructure=true`,
                    {
                        body: [metaField.metaFieldId.systemName],
                    }
                )
            ),
            map(() => metaField)
        );
    }

    public loadFields(
        categoryName: string,
        prefix: string
    ): Observable<List<MetaField>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}fields/${categoryName}`
                    )
                    .pipe(map(deserializeMetaFields))
            ),
            switchMap(fields => {
                return zip(
                    ...fields
                        .map(category => this.correctNameOfEntity(category))
                        .toArray()
                );
            }),
            map(fields => List(fields))
        );
    }

    public loadMainEntityFieldNames(
        categoryName: string,
        mainEntityFieldName: string,
        prefix?: string
    ): Observable<Set<string>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}mainentityfields/${categoryName}/${mainEntityFieldName}`
                    )
                    .pipe(
                        map((fieldDtos: MetaFieldDto[]) =>
                            Set(
                                fieldDtos.map(
                                    field => field.metaFieldId.systemName
                                )
                            )
                        )
                    )
            )
        );
    }

    public loadField(
        categoryName: string,
        fieldName: string,
        prefix: string
    ): Observable<MetaField> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}fields/${categoryName}/${fieldName}`
                    )
                    .pipe(map(createMetaField))
            ),
            switchMap(field => this.correctNameOfEntity(field))
        );
    }

    public createField(
        categoryName: string,
        field: SavableMetaFieldDto,
        prefix: string
    ): Observable<MetaField> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.post<MetaFieldDto[]>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/fields?updateDbStructure=true`,
                    [field]
                )
            ),
            map(([metaFieldDto]) => createMetaField(metaFieldDto))
        );
    }

    // endregion

    // region groups

    public loadGroups(
        categoryName: string,
        prefix: string
    ): Observable<List<Group>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http
                    .get(
                        `${url}/${this.urlCorrectionOnPrefix(
                            prefix
                        )}groups/${categoryName}`
                    )
                    .pipe(map(values => deserializeGroups(<GroupInfo[]>values)))
            ),
            switchMap(fields => {
                return zip(
                    ...fields
                        .map(category => this.correctNameOfEntity(category))
                        .toArray()
                );
            }),
            map(fields => List(fields))
        );
    }

    public createGroup(
        group: GroupInfo,
        categoryName: string,
        prefix: string
    ): Observable<Group> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.post<GroupInfo>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/groups`,
                    group
                )
            ),
            map(createGroup)
        );
    }

    public updateGroups(
        groups: List<GroupInfo>,
        categoryName: string,
        prefix: string
    ): Observable<List<Group>> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.patch<GroupInfo[]>(
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/groups`,
                    groups
                        .groupBy(g => g.systemName)
                        .map(value => value.get(0))
                        .toJS()
                )
            ),
            map(resp => List(resp.map(createGroup)))
        );
    }

    public deleteGroup(
        group: string,
        categoryName: string,
        prefix: string
    ): Observable<string> {
        return this.$kbUrl.pipe(
            switchMap(url =>
                this.http.request(
                    'DELETE',
                    `${url}/${this.urlCorrectionOnPrefix(
                        prefix
                    )}${categoryName}/groups`,
                    {
                        body: [group],
                    }
                )
            ),
            map(() => group)
        );
    }

    // endregion

    private convertMessageToMultilingual(
        name: string
    ): Observable<MultilingualString> {
        return languageStoreManager.selectAll(this.store).pipe(
            map(languages =>
                languages
                    .toSeq()
                    .map(language => language.getId())
                    .map(languageId => MessageKeyFactory({name, languageId}))
            ),
            switchMap(
                (keys: Seq<number, MessageKey>): Observable<Message[]> => {
                    return zip(
                        ...keys
                            .map(key =>
                                messageStoreManager
                                    .selectOne(this.store, key)
                                    .pipe(filter(notNil))
                            )
                            .toList()
                            .toArray()
                    );
                }
            ),
            map(messages =>
                messages
                    .reduce(
                        (builder, message) =>
                            builder.withValueForLanguage(
                                message.key.languageId,
                                message.value
                            ),
                        MultilingualString.newBuilder()
                    )
                    .build()
            )
        );
    }

    private correctNameOfEntity<T extends EntityWithName>(
        entityWithName: T
    ): Observable<T> {
        if (
            isEmptyMultilingual(entityWithName.displayNameMultilingual) &&
            !isEmpty(entityWithName.displayName)
        ) {
            return this.convertMessageToMultilingual(
                entityWithName.displayName!
            ).pipe(
                map(name => {
                    entityWithName.displayNameMultilingual = name;
                    return entityWithName;
                })
            );
        } else if (
            isNil(entityWithName.displayNameMultilingual) &&
            isEmpty(entityWithName.displayName)
        ) {
            entityWithName.displayNameMultilingual = MultilingualString.newBuilder().build();
            return of(entityWithName);
        } else {
            return of(entityWithName);
        }
    }

    private urlCorrectionOnPrefix(prefix?: string): string {
        return isNil(prefix) || prefix === defaultKbKey ? '' : prefix + '/';
    }
}
