import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {propertiesFeatureKey} from '@synisys/skynet-store-properties-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {PropertyService} from '../services/property.service';
import {PropertiesEffects} from './properties.effects';
import {propertiesReducer} from './properties.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(
            propertiesFeatureKey,
            propertiesReducer
        ),
        EffectsModule.forFeature([PropertiesEffects]),
    ],
    providers: [PropertyService],
})
export class PropertiesStoreModule {}
