import {Store} from '@ngrx/store';
import {Collection, List, Map} from 'immutable';
import {assign, get, isNil, memoize, MemoizedFunction} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {
    distinct,
    filter,
    first,
    map,
    publishReplay,
    refCount,
    switchMap,
} from 'rxjs/operators';
import {ActionPayload} from '../action-payload-type';
import {StandardActionTypes} from '../actions-types.enum';
import {EntityKey} from '../model/actions.model';
import {DynamicEntityActionTypes} from '../model/dynamic-actions.model';
import {StoreState} from '../state.model';
import {loadingStatusKey} from './constants';
import {createLogger} from './logging.utilities';

const logger = createLogger('dynamic-entity-store-manager');

export type dynamicSelectAllType<T, K> = MemoizedFunction &
    ((
        store: Store<StoreState>,
        subPath: string,
        additionalData?: object
    ) => Observable<List<T>>);

export class DynamicEntityStoreManager<T, K extends EntityKey, Savable = T> {
    public selectAll: dynamicSelectAllType<T, K> = memoize(
        this.loadAll,
        (...args) => {
            return `${this.path}${
                isNil(this.prefix) ? '' : '.' + this.prefix
            }.${args[1]}`;
        }
    );

    constructor(
        private readonly path: string,
        private readonly keyExtractor: (item: T) => K,
        protected readonly prefix?: string
    ) {}

    public actionType(standardType: StandardActionTypes): string {
        return `${this.path} ${standardType}`;
    }

    public createAction<A extends StandardActionTypes>(
        standardType: A,
        subPath: string,
        payload?: ActionPayload<A, T, K, Savable>,
        _additionalData?: object
    ): DynamicEntityActionTypes<A, T, K, Savable> {
        const additionalData = this.assignPrefixIfNeeded(_additionalData);
        switch (standardType) {
            case StandardActionTypes.LOAD_ALL:
                return {
                    type: this.actionType(standardType),
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.UPDATE_MANY_SUCCESS:
            case StandardActionTypes.UPDATE_MANY:
                return {
                    type: this.actionType(standardType),
                    items: payload as Collection<K, Savable>,
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.LOAD_MANY_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    items: payload as Collection<number | string, T>,
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.LOAD_ONE:
                return {
                    type: this.actionType(standardType),
                    id: payload as K,
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.CREATE:
            case StandardActionTypes.UPDATE:
                return {
                    type: this.actionType(standardType),
                    item: payload as Savable,
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.LOAD_ONE_SUCCESS:
            case StandardActionTypes.CREATE_SUCCESS:
            case StandardActionTypes.DELETE:
            case StandardActionTypes.DELETE_SUCCESS:
            case StandardActionTypes.UPDATE_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    item: payload as T,
                    subPath,
                    additionalData,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.CREATE_FAIL:
            case StandardActionTypes.ENTITY_ERROR:
                return {
                    type: this.actionType(standardType),
                    error: payload as string | Error,
                } as DynamicEntityActionTypes<A, T, K, Savable>;
            default:
                return undefined as DynamicEntityActionTypes<A, T, K, Savable>;
        }
    }

    public loadAll(
        store: Store<StoreState>,
        subPath: string,
        additionalData?: object
    ): Observable<List<T>> {
        store.dispatch(
            this.createAction(
                StandardActionTypes.LOAD_ALL,
                subPath,
                undefined,
                additionalData
            )
        );
        logger.debug(
            'loading following store item with following key %s',
            `${this.path}.${subPath}`
        );
        return this.fullyLoaded(store, subPath).pipe(
            switchMap(() =>
                store.select(state => get(state, this.getPathExtended()))
            ),
            filter((value: Map<string, List<T>>) => {
                return !isNil(value) && !value.get(subPath, List()).isEmpty();
            }),
            map(value => value.get(subPath, List())),
            publishReplay(1),
            refCount(),
            distinct()
        );
    }

    public clearCache(): void {
        this.selectAll.cache.clear!();
    }

    public selectOne(
        store: Store<StoreState>,
        id: K,
        subPath: string,
        additionalData?: object
    ): Observable<T | undefined> {
        store.dispatch(
            this.createAction(
                StandardActionTypes.LOAD_ONE,
                subPath,
                id,
                additionalData
            )
        );
        return this.selectAll(store, subPath).pipe(
            map(items =>
                items.find(
                    item =>
                        String(this.keyExtractor(item)).toLocaleLowerCase() ===
                        String(id).toLocaleLowerCase()
                )
            )
        );
    }

    private getPathExtended(): string {
        return `${this.path}${isNil(this.prefix) ? '' : '.' + this.prefix}`;
    }

    private assignPrefixIfNeeded(additionalData?: object): object | undefined {
        if (this.prefix) {
            return assign(additionalData, {__prefix__: this.prefix});
        }
        return additionalData;
    }

    private fullyLoaded(
        store: Store<StoreState>,
        subPath: string
    ): Observable<boolean> {
        const loadingStatus =
            this.getPathExtended().split('.')[0] + '.' + loadingStatusKey;
        const loadStatusPath = [
            ...this.getPathExtended()
                .split('.')
                .slice(1),
            subPath,
        ].join('.');
        return store
            .select(state => get(state, loadingStatus))
            .pipe(
                map(status => isNil(status) || get(status, loadStatusPath)),
                filter(Boolean),
                first()
            );
    }
}
