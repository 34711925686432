import {Store} from '@ngrx/store';
import {assert, notNil} from '@synisys/skynet-store-utilities';
import {Observable} from 'rxjs/Observable';
import {filter, map} from 'rxjs/operators';
import {CurrencyModel} from './currency.models';
import {CurrencyRateModel} from './currency-rate.models';
import {EntityStoreManager} from '@synisys/skynet-store-manager';
import {CurrencyState} from './currency.state';

export const currencyStoreManager: EntityStoreManager<
    CurrencyModel,
    number
> = new EntityStoreManager('currencies.currencies', item => item.id);

export const currencyRateStoreManager: EntityStoreManager<
    CurrencyRateModel,
    string
> = new EntityStoreManager<CurrencyRateModel, string>(
    'currencies.rates',
    keyExtractor
);

export function keyExtractor(
    item: CurrencyRateModel | {timestamp: number; currencyId: number}
): string {
    const date = new Date(item.timestamp);
    date.setUTCHours(0, 0, 0, 0);

    return JSON.stringify({
        currencyId: item.currencyId,
        date: date.toISOString().replace('T00:00:00.000Z', ''),
    });
}

export function dataExtractor(
    key: string
): {timestamp: number; currencyId: number} {
    const parsed: {date: string; currencyId: number} = JSON.parse(key);
    return {
        timestamp: new Date(parsed.date).getTime(),
        currencyId: parsed.currencyId,
    };
}

export function getDefaultCurrency(
    store: Store<CurrencyState>
): Observable<CurrencyModel> {
    return currencyStoreManager.selectAll(store).pipe(
        filter(value => !value.isEmpty()),
        map(currencies =>
            currencies.valueSeq().find(currency => currency.isDefault)
        ),
        assert(notNil, Error(`Can't find default currency`))
    );
}
