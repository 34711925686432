import {
    localeFeatureKey,
    LocaleInfo,
    LocaleState,
    localeStoreManager,
} from '@synisys/skynet-store-locales-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadOneSuccessAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {Map} from 'immutable';

export function localesReducer(
    state: LocaleState[typeof localeFeatureKey] = {
        data: Map<number, LocaleInfo>(),
        error: undefined,
    },
    action: EntityActionTypes<
        StandardActionTypes.LOAD_ONE_SUCCESS | StandardActionTypes.ENTITY_ERROR,
        LocaleInfo,
        number
    >
): LocaleState[typeof localeFeatureKey] {
    switch (action.type) {
        case localeStoreManager.actionType(
            StandardActionTypes.LOAD_ONE_SUCCESS
        ): {
            return {
                ...state,
                data: state.data.set(
                    action.additionalData!['languageId'],
                    (action as EntityStoreLoadOneSuccessAction<LocaleInfo>).item
                ),
            };
        }
        case localeStoreManager.actionType(StandardActionTypes.ENTITY_ERROR): {
            return {
                ...state,
                error: (action as EntityStoreErrorAction).error,
            };
        }
        default:
            return state;
    }
}
