import {NgModule} from '@angular/core';
import {Store} from '@ngrx/store';
import {KbReloadService, kbReloadServiceFactory} from './_kb-reload.service';

@NgModule({
    providers: [
        {
            provide: KbReloadService,
            useFactory: kbReloadServiceFactory,
            deps: [Store],
        },
    ],
})
export class KbReloadModule {
    constructor(private _?: KbReloadService) {}
}
