import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    Message,
    MessageFactory,
    MessageKeyFactory,
} from '@synisys/skynet-store-messages-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {entries, get, set} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class MessageService {
    private readonly $mlUrl = getProperties(this.store).pipe(
        map(propertyMap => get(propertyMap, 'ml-service-url')),
        assert(
            notNil,
            new StoreManagerError('Url for ml-service-url was not found')
        )
    );

    constructor(
        private readonly store: Store<PropertiesState>,
        private readonly http: HttpClient
    ) {}

    public loadMessages(
        languageId: number,
        ...keys: string[]
    ): Observable<List<Message>> {
        return this.$mlUrl.pipe(
            switchMap(url =>
                this.http
                    .get(`${url}/messages`, {
                        params: {
                            languageId: languageId.toString(),
                            keys: keys.join(','),
                        },
                    })
                    .pipe(
                        map(value =>
                            List(entries(set(value, '', ''))).map(message =>
                                MessageFactory({
                                    key: MessageKeyFactory({
                                        languageId,
                                        name: message[0],
                                    }),
                                    value: message[1],
                                })
                            )
                        )
                    )
            )
        );
    }
}
