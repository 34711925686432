import * as debug from 'debug';

export enum LogLevel {
    debug = 0,
    info = 1,
    warn = 2,
    error = 3,
    none = 4,
}

enableErrorIfEmpty();

export class Logger {
    public static setLevel(logLevel: LogLevel): void {
        const currentNamespace = getCurrentNamespace();
        const logLevelNamespace = namespaceForLogLevel(logLevel);
        if (currentNamespace.length > logLevelNamespace.length) {
            // disabling some logs
            console.warn(`Log level is set to ${LogLevel[logLevel]}`);
        }
        debug.enable(logLevelNamespace);
    }

    private readonly debugLogFunc: debug.Debugger;
    private readonly infoLogFunc: debug.Debugger;
    private readonly warnLogFunc: debug.Debugger;
    private readonly errorLogFunc: debug.Debugger;

    constructor(
        private readonly module: string,
        private readonly scope: string
    ) {
        this.debugLogFunc = debug(`debug:${module}:${scope}`);
        // tslint:disable-next-line:no-console
        this.debugLogFunc.log = console.debug.bind(console);

        this.errorLogFunc = debug(`error:${module}:${scope}`);
        // tslint:disable-next-line:no-console
        this.errorLogFunc.log = console.error.bind(console);

        this.warnLogFunc = debug(`warn:${module}:${scope}`);
        // tslint:disable-next-line:no-console
        this.warnLogFunc.log = console.warn.bind(console);

        this.infoLogFunc = debug(`info:${module}:${scope}`);
        // tslint:disable-next-line:no-console
        this.infoLogFunc.log = console.info.bind(console);
    }

    public warn(message: string, ...args): void {
        this.warnLogFunc(message, ...args);
    }

    public info(message: string, ...args): void {
        this.infoLogFunc(message, ...args);
    }

    public debug(message: string, ...args): void {
        this.debugLogFunc(message, ...args);
    }

    public error(message: string, ...args): void {
        this.errorLogFunc(message, ...args);
    }
}

function getCurrentNamespace(): string {
    const namespace = debug.disable();
    debug.enable(namespace);
    return namespace;
}

function namespaceForLogLevel(logLevel: LogLevel): string {
    return Object.keys(exports.LogLevel)
        .filter(key => !isNaN(Number(key)) && Number(key) >= logLevel)
        .map(key => LogLevel[key])
        .map(value => value + ':*')
        .join(',');
}

function enableErrorIfEmpty(): void {
    if (!getCurrentNamespace()) {
        debug.enable('error:*');
    }
}
