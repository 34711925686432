import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthService {
    private static defaultHeaders = {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic Y2xpZW50OnNlY3JldA==',
    };

    private authUrl = getProperties(this.store).pipe(
        map(properties => get(properties, 'auth-service-url')),
        assert(
            notNil,
            new StoreManagerError('Url for auth-service-url was not found')
        )
    );

    constructor(
        private httpClient: HttpClient,
        private store: Store<PropertiesState>
    ) {}

    public login(
        username: string,
        password: string,
        headers?: {[key: string]: string}
    ): Observable<{access_token: string}> {
        return this.authUrl.pipe(
            switchMap(url =>
                this.httpClient.post<{access_token: string}>(
                    `${url}/oauth/token?grant_type=password&username=${username}&password=${password}&scope`,
                    undefined,
                    {
                        headers: headers ? headers : AuthService.defaultHeaders,
                    }
                )
            )
        );
    }
}
