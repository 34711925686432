import {
    ENTITY_MULTILINGUAL_NAME_ATTRIBUTE,
    MetaField,
    MetaFieldDto,
    SavableMetaFieldDto,
} from '@synisys/skynet-store-kb-api';
import {
    deserializeIfMultilingual,
    deserializeMultilingual,
    MultilingualStringDto,
    notNil,
    serializeMultilingualString,
} from '@synisys/skynet-store-utilities';
import {hash, List} from 'immutable';
import {mapValues} from 'lodash';
import {makeValueObject, toLower} from '../kb.utilities';

export function deserializeMetaFields(
    fieldDtos: MetaFieldDto[]
): List<MetaField> {
    return List(fieldDtos).map(createMetaField);
}

export function createMetaField(fieldDto: MetaFieldDto): MetaField {
    const metaField = <MetaField>mapValues(fieldDto, (value, key) => {
        if (key === ENTITY_MULTILINGUAL_NAME_ATTRIBUTE) {
            return deserializeMultilingual(value as MultilingualStringDto);
        } else {
            return deserializeIfMultilingual(value);
        }
    });
    return makeValueObject(
        metaField,
        (first, other) =>
            toLower(first.metaFieldId.systemName) ===
                toLower(other.metaFieldId.systemName) &&
            toLower(first.metaFieldId.metaCategoryId.systemName) ===
                toLower(other.metaFieldId.metaCategoryId.systemName),
        obj =>
            hash(obj.metaFieldId.systemName) * 31 +
            hash(obj.metaFieldId.metaCategoryId.systemName)
    );
}

export function metaFieldToSavable(metaField: MetaField): SavableMetaFieldDto {
    return {
        group: metaField.metaFieldId.groupId,
        systemName: metaField.metaFieldId.systemName,
        displayName: notNil(metaField.displayName) ? metaField.displayName : '',
        isSystemField: metaField.isSystemField,
        isKeyField: metaField.isKeyField,
        isName: metaField.isName,
        columnName: metaField.columnName,
        displayNameMultilingual: notNil(metaField.displayNameMultilingual)
            ? serializeMultilingualString(metaField.displayNameMultilingual)
            : undefined,
        // TODO: implement in backend
        pluralName: undefined,
        description: undefined,
        isMultiline: metaField.isMultiline,
        metaFieldType: metaField.metaFieldType,
        metaCategoryId:
            metaField.classifierMetaCategoryId ||
            metaField.lookupMetaCategoryId ||
            metaField.subEntityMetaCategoryId ||
            metaField.parentMetaCategoryId ||
            metaField.mainEntityCategoryId ||
            metaField.documentMetaCategoryId ||
            metaField.workflowStateMetaCategoryId,
        isImage: metaField.isImage,
        hintKey: metaField.hintKey,
        maxLength: metaField.maxLength,
        fieldPrefix: metaField.fieldPrefix,
        isSearchableField: metaField.isSearchableField,
        isTransient: metaField.isTransient,
        sortInfo: metaField.sortInfo,
    };
}
