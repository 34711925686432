import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {authenticationFeatureKey} from '@synisys/skynet-store-authentication-api';
import {SkynetStoreFeatureModule} from '@synisys/skynet-store-manager';
import {AuthService} from '../services/auth.service';
import {AuthEffects} from './auth.effects';
import {authenticationReducer} from './auth.reducers';

@NgModule({
    imports: [
        SkynetStoreFeatureModule.forFeature(
            authenticationFeatureKey,
            authenticationReducer
        ),
        EffectsModule.forFeature([AuthEffects]),
    ],
    providers: [AuthService],
})
export class AuthStoreModule {}
