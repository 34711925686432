import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrencyDto, CurrencyModel} from '@synisys/skynet-store-currencies-api';
import {
    getProperties,
    PropertiesState,
} from '@synisys/skynet-store-properties-api';
import {
    assert,
    deserializeIfMultilingual,
    notNil,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {get} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Injectable()
export class CurrencyService {
    private readonly currencyUrl$: Observable<string> = getProperties(
        this.store
    ).pipe(
        map(propertyMap => get(propertyMap, 'currency-rate-service-url')),
        assert(
            notNil,
            new StoreManagerError('Url for currency-rate-service was not found')
        ),
        publishReplay<string>(1),
        refCount()
    );

    constructor(
        private http: HttpClient,
        private store: Store<PropertiesState>
    ) {}

    public getAllCurrencies(): Observable<List<CurrencyModel>> {
        return this.currencyUrl$.pipe(
            switchMap(uri => this.http.get<object[]>(`${uri}/currencies`)),
            map(value => List(value.map(deserializeCurrency)))
        );
    }
}

export function deserializeCurrency(response: CurrencyDto): CurrencyModel {
    return {
        ...response,
        name: <MultilingualString>(
            deserializeIfMultilingual(response.name.values)
        ),
    };
}
