import {Map} from 'immutable';
import {LocaleInfo} from './locale-info.model';

export const localeFeatureKey = 'locales';

export interface LocaleState {
    [localeFeatureKey]: {
        data: Map<number, LocaleInfo>;
        error: string | Error | undefined;
    };
}
