import {Store} from '@ngrx/store';
import {DynamicEntityStoreManager} from '@synisys/skynet-store-manager';
import {assert, notNil, NullOrUndefined} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {Memoize} from 'lodash-decorators';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {
    distinct,
    filter,
    map,
    publishReplay,
    refCount,
    switchMap,
} from 'rxjs/operators';
import {KbState, MetaCategoryId} from '..';
import {LoadMainEntitiesAction} from '../actions/load-main-entities.action';
import {MetaField, MetaFieldId, SavableMetaFieldDto} from '../model/index';

export function mainEntityKey(_, field: MetaFieldId): string {
    return field.systemName + '--' + field.metaCategoryId.systemName;
}

export class FieldStoreManager extends DynamicEntityStoreManager<
    MetaField,
    string,
    SavableMetaFieldDto
> {
    constructor(
        path: string,
        keyExtractor: (item: MetaField) => string,
        prefix: string
    ) {
        super(path, keyExtractor, prefix);
    }

    @Memoize(mainEntityKey)
    public loadMainEntityFields(
        store: Store<KbState>,
        metaFieldId: MetaFieldId,
        compoundCategory?: MetaCategoryId | NullOrUndefined
    ): Observable<List<MetaField>> {
        store.dispatch(new LoadMainEntitiesAction(metaFieldId, this.prefix));
        const categoryName = metaFieldId.metaCategoryId.systemName;
        const fieldName = metaFieldId.systemName;
        const fieldNames$ = store
            .select(state => state.kb.mainEntityFields[this.prefix!])
            .pipe(
                filter(notNil),
                filter(value => value.has(categoryName)),
                map(value => value.get(categoryName)!),
                filter(value => value.has(fieldName)),
                map(value => value.get(fieldName)!)
            );
        let compoundCategory$: Observable<MetaCategoryId>;
        if (notNil(compoundCategory)) {
            compoundCategory$ = of(compoundCategory);
        } else {
            compoundCategory$ = this.selectOne(
                store,
                fieldName,
                categoryName
            ).pipe(
                filter(notNil),
                map(metaField => metaField.mainEntityCategoryId),
                assert(notNil, Error(`${fieldName} is not Main entity`))
            );
        }
        return combineLatest(fieldNames$, compoundCategory$).pipe(
            switchMap(([fieldNames, compound]) =>
                this.selectAll(store, compound.systemName).pipe(
                    map(fields =>
                        fields.filter(field =>
                            fieldNames.has(field.metaFieldId.systemName)
                        )
                    )
                )
            ),
            publishReplay<List<MetaField>>(1),
            refCount(),
            distinct()
        );
    }

    public clearCache(): void {
        super.clearCache();
        this.loadMainEntityFields['cache'].clear();
    }
}
