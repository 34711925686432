import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadAllSuccessAction,
    EntityStoreLoadOneSuccessAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {
    Message,
    MessageKey,
    MessageKeyDto,
    messagesFeatureKey,
    MessageState,
    messageStoreManager,
} from '@synisys/skynet-store-messages-api';
import {Map, RecordOf} from 'immutable';

export function messagesReducer(
    state: MessageState[typeof messagesFeatureKey] = {
        data: Map<RecordOf<MessageKeyDto>, Message>(),
        error: undefined,
    },
    action: EntityActionTypes<
        | StandardActionTypes.LOAD_ONE_SUCCESS
        | StandardActionTypes.LOAD_MANY_SUCCESS
        | StandardActionTypes.ENTITY_ERROR,
        Message,
        number
    >
): MessageState[typeof messagesFeatureKey] {
    switch (action.type) {
        case messageStoreManager.actionType(
            StandardActionTypes.LOAD_ONE_SUCCESS
        ):
            const msg = (action as EntityStoreLoadOneSuccessAction<Message>)
                .item;
            return {
                ...state,
                data: state.data.set(msg.key, msg),
            };
        case messageStoreManager.actionType(
            StandardActionTypes.LOAD_MANY_SUCCESS
        ):
            const items = (action as EntityStoreLoadAllSuccessAction<Message>)
                .items as Map<MessageKey, Message>;
            if (items !== undefined) {
                return {
                    ...state,
                    data: state.data.merge(items.toMap()),
                };
            } else {
                return state;
            }
        case messageStoreManager.actionType(StandardActionTypes.ENTITY_ERROR):
            return {
                ...state,
                error: (<EntityStoreErrorAction>action).error,
            };
        default:
            return state;
    }
}
