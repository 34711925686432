import {List} from 'immutable';
import {StoreLanguage} from './models';

export const languageStateKey = 'languages';

export interface LanguagesState {
    [languageStateKey]: {
        languages: List<StoreLanguage>;
        currentLanguageId: number | undefined;
        error: string | Error | undefined;
    };
}
