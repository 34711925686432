import {
    MultilingualString,
    MultilingualStringBuilder,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {Group, MetaCategory, MetaField} from '@synisys/skynet-store-kb-api';
import {ValueObject} from 'immutable';
import {cloneDeepWith, isNil} from 'lodash';
import {Logger, NullOrUndefined} from '@synisys/skynet-store-utilities';

export function cloneCategory(category: MetaCategory): MetaCategory {
    return cloneEntity(category);
}

export function cloneEntity<T extends MetaCategory | MetaField>(
    category: T
): T {
    return cloneDeepWith(category, value => {
        if (value instanceof MultilingualString) {
            const cloneBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
            value.toMap().forEach((val, key) => {
                cloneBuilder.withValueForLanguage(key, val);
            });
            return cloneBuilder.build();
        } else if (value) {
        }
    });
}

export function createLogger(namespace: string): Logger {
    return new Logger('skynet-store-kb-impl', namespace);
}

export function makeValueObject<T>(
    value: T,
    equals: (first: T, other: T) => boolean,
    hashCode: (obj: T) => number
): T & ValueObject {
    const newValue = value as T & ValueObject;
    newValue.equals = function(other): boolean {
        // tslint:disable-next-line:no-invalid-this
        return equals(this, other);
    };
    newValue.hashCode = function(): number {
        // tslint:disable-next-line:no-invalid-this
        return hashCode(this);
    };
    return newValue;
}

export type ExcludeParent<T extends P, P> = Pick<T, Exclude<keyof T, keyof P>>;

export function toLower(
    str: string | NullOrUndefined
): string | NullOrUndefined {
    return isNil(str) ? str : str.toLowerCase();
}
