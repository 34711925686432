import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    CurrencyModel,
    CurrencyRateModel,
    currencyRateStoreManager,
    CurrencyState,
    currencyStoreManager,
    dataExtractor,
} from '@synisys/skynet-store-currencies-api';
import {
    EntityActionTypes,
    EntityStoreErrorAction,
    EntityStoreLoadOneAction,
    StandardActionTypes,
} from '@synisys/skynet-store-manager';
import {List} from 'immutable';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {CurrencyRateService} from '../services/currency-rate.service';
import {get} from 'lodash';
import {CurrencyService} from '../services/currency.service';
import {createLogger} from '../currency.utilities';
import {
    logStoreError,
    StoreManagerError,
} from '@synisys/skynet-store-utilities';

const logger = createLogger('currency-effects');

@Injectable()
export class CurrencyEffects {
    @Effect()
    public loadCurrencies$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_ALL
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            CurrencyModel,
            number
        >
    > = this.actions$.pipe(
        ofType(currencyStoreManager.actionType(StandardActionTypes.LOAD_ALL)),
        switchMap(action =>
            this.store.select(state => state.currencies.currencies)
        ),
        switchMap(loadedCurrencies => {
            if (!loadedCurrencies.isEmpty()) {
                return of(
                    currencyStoreManager.createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        loadedCurrencies
                    )
                );
            }
            logger.debug('requesting currency service to load currencies');
            return this.currencyService.getAllCurrencies().pipe(
                map((currencies: List<CurrencyModel>) =>
                    currencyStoreManager.createAction(
                        StandardActionTypes.LOAD_MANY_SUCCESS,
                        currencies
                    )
                ),
                catchError(err => {
                    return of(
                        currencyStoreManager.createAction(
                            StandardActionTypes.ENTITY_ERROR,
                            new StoreManagerError(err)
                        )
                    );
                })
            );
        })
    );

    @Effect()
    public loadCurrency$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_ONE_SUCCESS
            | StandardActionTypes.LOAD_MANY_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            CurrencyModel,
            number
        >
    > = this.actions$.pipe(
        ofType(currencyStoreManager.actionType(StandardActionTypes.LOAD_ONE)),
        mergeMap((action: EntityStoreLoadOneAction<number>) =>
            this.store
                .select(state => state.currencies.currencies)
                .pipe(
                    switchMap(loadedCurrencies => {
                        if (!loadedCurrencies.isEmpty()) {
                            return [
                                currencyStoreManager.createAction(
                                    StandardActionTypes.LOAD_MANY_SUCCESS,
                                    loadedCurrencies
                                ),
                                currencyStoreManager.createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    loadedCurrencies.get(action.id)
                                ),
                            ];
                        }
                        logger.debug(
                            'requesting currency service to load currencies'
                        );
                        return this.currencyService.getAllCurrencies().pipe(
                            switchMap((currencies: List<CurrencyModel>) => [
                                currencyStoreManager.createAction(
                                    StandardActionTypes.LOAD_MANY_SUCCESS,
                                    currencies
                                ),
                                currencyStoreManager.createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    currencies.get(action.id)
                                ),
                            ]),
                            catchError(err => {
                                return of(
                                    currencyStoreManager.createAction(
                                        StandardActionTypes.ENTITY_ERROR,
                                        new StoreManagerError(err)
                                    )
                                );
                            })
                        );
                    })
                )
        )
    );

    @Effect()
    public loadRate$: Observable<
        EntityActionTypes<
            | StandardActionTypes.LOAD_ONE_SUCCESS
            | StandardActionTypes.ENTITY_ERROR,
            CurrencyRateModel,
            string
        >
    > = this.actions$.pipe(
        ofType(
            currencyRateStoreManager.actionType(StandardActionTypes.LOAD_ONE)
        ),
        mergeMap((action: EntityStoreLoadOneAction<string>) => {
            const args = dataExtractor(action.id);
            return this.store
                .select(state => state.currencies.rates)
                .pipe(
                    switchMap(loadedCurrencyRates => {
                        if (loadedCurrencyRates.has(action.id)) {
                            return of(
                                currencyRateStoreManager.createAction(
                                    StandardActionTypes.LOAD_ONE_SUCCESS,
                                    loadedCurrencyRates.get(action.id)
                                )
                            );
                        }
                        logger.debug(
                            'requesting currency rate service to load rates'
                        );
                        return this.currencyRateService
                            .getRate(args.currencyId, args.timestamp)
                            .pipe(
                                map((rate: CurrencyRateModel) =>
                                    currencyRateStoreManager.createAction(
                                        StandardActionTypes.LOAD_ONE_SUCCESS,
                                        rate
                                    )
                                ),
                                catchError(err => {
                                    return of(
                                        currencyRateStoreManager.createAction(
                                            StandardActionTypes.ENTITY_ERROR,
                                            new StoreManagerError(err)
                                        )
                                    );
                                })
                            );
                    })
                );
        })
    );

    @Effect({dispatch: false})
    public printError = this.actions$.pipe(
        ofType(
            currencyStoreManager.actionType(StandardActionTypes.ENTITY_ERROR),
            currencyRateStoreManager.actionType(
                StandardActionTypes.ENTITY_ERROR
            )
        ),
        map((action: EntityStoreErrorAction) => {
            if (get(action.error, 'type') === 'StoreManagerError') {
                logStoreError(<StoreManagerError>action.error, logger);
            } else {
                logger.error(
                    'error while loading: error => \n %o',
                    action.error
                );
            }
        }),
        tap(noop, e => logger.error(e))
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<CurrencyState>,
        private readonly currencyService: CurrencyService,
        private readonly currencyRateService: CurrencyRateService
    ) {}
}
