import {select, Store} from '@ngrx/store';
import {notNil} from '@synisys/skynet-store-utilities';
import {Collection, List, Record} from 'immutable';
import {assign, get, isNil, memoize, MemoizedFunction, negate} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {
    distinct,
    filter,
    first,
    map,
    publishReplay,
    refCount,
    switchMap,
} from 'rxjs/operators';
import {ActionPayload} from '../action-payload-type';
import {StandardActionTypes} from '../actions-types.enum';
import {EntityActionTypes, EntityKey} from '../model/actions.model';
import {StoreState} from '../state.model';
import {isEmptyState} from '../utilities';
import {loadingStatusKey} from './constants';
import {createLogger} from './logging.utilities';

const logger = createLogger('entity-store-manager');

export type selectOneType<T, K> = MemoizedFunction &
    ((
        store: Store<StoreState>,
        id: K,
        additionalData?: object
    ) => Observable<T | undefined>);

export type selectAllType<T, K> = MemoizedFunction &
    ((
        store: Store<StoreState>,
        additionalData?: object
    ) => Observable<Collection<EntityKey, T>>);

export class EntityStoreManager<T, K extends EntityKey, Savable = T> {
    public selectAll: selectAllType<T, K> = memoize(
        this.loadAll,
        args => `${this.path}${isNil(this.prefix) ? '' : '.' + this.prefix}`
    );
    public selectOne: selectOneType<T, K> = memoize(
        this.loadOne,
        (_, key, __) => {
            if (Record.isRecord(key)) {
                return key.toSeq().join('.');
            } else {
                return `${this.path}${
                    isNil(this.prefix) ? '' : '.' + this.prefix
                }.${String(key)}`;
            }
        }
    );

    constructor(
        private readonly path: string,
        private readonly keyExtractor: (item: T) => K,
        protected readonly prefix?: string,
        private readonly isEmptyPredicate: (
            // tslint:disable-next-line:no-any
            state: any
        ) => boolean = isEmptyState
    ) {}

    public actionType(standardType: StandardActionTypes): string {
        return `${this.path} ${standardType}`;
    }

    public createAction<A extends StandardActionTypes>(
        standardType: A,
        payload?: ActionPayload<A, T, K, Savable>,
        _additionalData?: object
    ): EntityActionTypes<A, T, K, Savable> {
        const additionalData = this.assignPrefixIfNeeded(_additionalData);
        switch (standardType) {
            case StandardActionTypes.LOAD_ALL:
                return {
                    type: this.actionType(standardType),
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.UPDATE_MANY: {
                return {
                    type: this.actionType(standardType),
                    items: payload as Collection<K, Savable>,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            }
            case StandardActionTypes.UPDATE_MANY_SUCCESS:
            case StandardActionTypes.LOAD_MANY_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    items: payload as Collection<K, T>,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.LOAD_ONE:
                return {
                    type: this.actionType(standardType),
                    id: payload as K,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.CREATE:
            case StandardActionTypes.UPDATE:
                return {
                    type: this.actionType(standardType),
                    item: payload as Savable,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.CREATE_SUCCESS:
            case StandardActionTypes.UPDATE_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    item: payload as Savable,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.DELETE:
            case StandardActionTypes.DELETE_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    item: payload as Savable,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.LOAD_ONE_SUCCESS:
                return {
                    type: this.actionType(standardType),
                    item: payload as T,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            case StandardActionTypes.CREATE_FAIL:
            case StandardActionTypes.ENTITY_ERROR:
                return {
                    type: this.actionType(standardType),
                    error: payload as string | Error,
                    additionalData,
                } as EntityActionTypes<A, T, K, Savable>;
            default:
                throw new TypeError(`unknown action type ${standardType}`);
        }
    }

    public clearCache(): void {
        this.selectOne.cache.clear!();
        this.selectAll.cache.clear!();
    }

    private getPathExtended(): string {
        return `${this.path}${isNil(this.prefix) ? '' : '.' + this.prefix}`;
    }

    private assignPrefixIfNeeded(additionalData?: object): object | undefined {
        if (this.prefix) {
            return assign(additionalData, {__prefix__: this.prefix});
        }
        return additionalData;
    }

    private loadOne(
        store: Store<StoreState>,
        id: K,
        additionalData?: object
    ): Observable<T | undefined> {
        store.dispatch(
            this.createAction(StandardActionTypes.LOAD_ONE, id, additionalData)
        );
        return store
            .select(state => get(state, this.getPathExtended()))
            .pipe(
                filter(negate(this.isEmptyPredicate)),
                filter(items =>
                    items.some(item => {
                        const key = this.keyExtractor(item);
                        if (Record.isRecord(key)) {
                            return key.equals(id);
                        } else {
                            return (
                                String(key).toLocaleLowerCase() ===
                                String(id).toLocaleLowerCase()
                            );
                        }
                    })
                ),
                map(items =>
                    items.find(item => {
                        const key = this.keyExtractor(item);
                        if (Record.isRecord(key)) {
                            return key.equals(id);
                        } else {
                            return (
                                String(key).toLocaleLowerCase() ===
                                String(id).toLocaleLowerCase()
                            );
                        }
                    })
                ),
                distinct()
            );
    }

    private loadAll(
        store: Store<StoreState>,
        additionalData?: object
    ): Observable<List<T>> {
        store.dispatch(
            this.createAction(
                StandardActionTypes.LOAD_ALL,
                undefined,
                additionalData
            )
        );
        logger.debug(
            'loading following store item with following key %s',
            `${this.path}`
        );
        return this.fullyLoaded(store).pipe(
            switchMap(() =>
                store.select(state => get(state, this.getPathExtended()))
            ),
            filter(negate(this.isEmptyPredicate)),
            publishReplay(1),
            refCount()
        );
    }

    private fullyLoaded(store: Store<StoreState>): Observable<boolean> {
        const featureName = this.getPathExtended().split('.')[0];
        const loadingStatus = featureName + '.' + loadingStatusKey;
        const loadStatusPath = this.getPathExtended()
            .split('.')
            .slice(1)
            .join('.');
        return store.pipe(
            filter(state => notNil(get(state, featureName))),
            select(state => get(state, loadingStatus)),
            map(status => isNil(status) || get(status, loadStatusPath)),
            filter(Boolean),
            first()
        );
    }
}
